import React from 'react';

import { useLocation } from 'react-router-dom';

import Link from './Link';
import Profile from './Profile';
import { Container, Navigation } from './styles';

const links = [
	{
		to: '/admin',
		label: 'Dashboard',
	},
	{
		to: '/admin/rodadas',
		label: 'Rodadas',
	},
	{
		to: '/admin/times',
		label: 'Times',
	},
	{
		to: '/admin/usuarios',
		label: 'Usuários',
	},
];

const Menu = () => {
	const { pathname } = useLocation();

	return (
		<Container>
			<Navigation>
				{links.map(({ to, label }) => (
					<Link key={to} to={to} label={label} active={pathname === to} />
				))}
			</Navigation>
			<Profile />
		</Container>
	);
};

export default Menu;
