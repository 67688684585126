export default [
  {
    order: 1,
    name: 'Afeganistão',
    initials: 'AF',
    initials_3: 'AFG',
    id: '004',
  },
  {
    order: 2,
    name: 'África do Sul',
    initials: 'ZA',
    initials_3: 'ZAF',
    id: '710',
  },
  {
    order: 3,
    name: 'Albânia',
    initials: 'AL',
    initials_3: 'ALB',
    id: '008',
  },
  {
    order: 4,
    name: 'Alemanha',
    initials: 'DE',
    initials_3: 'DEU',
    id: '276',
  },
  {
    order: 5,
    name: 'Andorra',
    initials: 'AD',
    initials_3: 'AND',
    id: '020',
  },
  {
    order: 6,
    name: 'Angola',
    initials: 'AO',
    initials_3: 'AGO',
    id: '024',
  },
  {
    order: 7,
    name: 'Anguilla',
    initials: 'AI',
    initials_3: 'AIA',
    id: '660',
  },
  {
    order: 8,
    name: 'Antártordera',
    initials: 'AQ',
    initials_3: 'ATA',
    id: '010',
  },
  {
    order: 9,
    name: 'Antígua e Barbuda',
    initials: 'AG',
    initials_3: 'ATG',
    id: '028',
  },
  {
    order: 10,
    name: 'Antilhas Holandesas',
    initials: 'AN',
    initials_3: 'ANT',
    id: '530',
  },
  {
    order: 11,
    name: 'Arábia Saudita',
    initials: 'SA',
    initials_3: 'SAU',
    id: '682',
  },
  {
    order: 12,
    name: 'Argélia',
    initials: 'DZ',
    initials_3: 'DZA',
    id: '012',
  },
  {
    order: 13,
    name: 'Argentina',
    initials: 'AR',
    initials_3: 'ARG',
    id: '032',
  },
  {
    order: 14,
    name: 'Armênia',
    initials: 'AM',
    initials_3: 'ARM',
    id: '51',
  },
  {
    order: 15,
    name: 'Aruba',
    initials: 'AW',
    initials_3: 'ABW',
    id: '533',
  },
  {
    order: 16,
    name: 'Austrália',
    initials: 'AU',
    initials_3: 'AUS',
    id: '036',
  },
  {
    order: 17,
    name: 'Áustria',
    initials: 'AT',
    initials_3: 'AUT',
    id: '040',
  },
  {
    order: 18,
    name: 'Azerbaijão',
    initials: 'AZ  ',
    initials_3: 'AZE',
    id: '31',
  },
  {
    order: 19,
    name: 'Bahamas',
    initials: 'BS',
    initials_3: 'BHS',
    id: '044',
  },
  {
    order: 20,
    name: 'Bahrein',
    initials: 'BH',
    initials_3: 'BHR',
    id: '048',
  },
  {
    order: 21,
    name: 'Bangladesh',
    initials: 'BD',
    initials_3: 'BGD',
    id: '050',
  },
  {
    order: 22,
    name: 'Barbados',
    initials: 'BB',
    initials_3: 'BRB',
    id: '052',
  },
  {
    order: 23,
    name: 'Belarus',
    initials: 'BY',
    initials_3: 'BLR',
    id: '112',
  },
  {
    order: 24,
    name: 'Bélgica',
    initials: 'BE',
    initials_3: 'BEL',
    id: '056',
  },
  {
    order: 25,
    name: 'Belize',
    initials: 'BZ',
    initials_3: 'BLZ',
    id: '084',
  },
  {
    order: 26,
    name: 'Benin',
    initials: 'BJ',
    initials_3: 'BEN',
    id: '204',
  },
  {
    order: 27,
    name: 'Bermudas',
    initials: 'BM',
    initials_3: 'BMU',
    id: '060',
  },
  {
    order: 28,
    name: 'Bolívia',
    initials: 'BO',
    initials_3: 'BOL',
    id: '068',
  },
  {
    order: 29,
    name: 'Bósnia-Herzegóvina',
    initials: 'BA',
    initials_3: 'BIH',
    id: '070',
  },
  {
    order: 30,
    name: 'Botsuana',
    initials: 'BW',
    initials_3: 'BWA',
    id: '072',
  },
  {
    order: 31,
    name: 'Brasil',
    initials: 'BR',
    initials_3: 'BRA',
    id: '076',
  },
  {
    order: 32,
    name: 'Brunei',
    initials: 'BN',
    initials_3: 'BRN',
    id: '096',
  },
  {
    order: 33,
    name: 'Bulgária',
    initials: 'BG',
    initials_3: 'BGR',
    id: '100',
  },
  {
    order: 34,
    name: 'Burkina Fasso',
    initials: 'BF',
    initials_3: 'BFA',
    id: '854',
  },
  {
    order: 35,
    name: 'Burundi',
    initials: 'BI',
    initials_3: 'BDI',
    id: '108',
  },
  {
    order: 36,
    name: 'Butão',
    initials: 'BT',
    initials_3: 'BTN',
    id: '064',
  },
  {
    order: 37,
    name: 'Cabo Verde',
    initials: 'CV',
    initials_3: 'CPV',
    id: '132',
  },
  {
    order: 38,
    name: 'Camarões',
    initials: 'CM',
    initials_3: 'CMR',
    id: '120',
  },
  {
    order: 39,
    name: 'Camboja',
    initials: 'KH',
    initials_3: 'KHM',
    id: '116',
  },
  {
    order: 40,
    name: 'Canadá',
    initials: 'CA',
    initials_3: 'CAN',
    id: '124',
  },
  {
    order: 41,
    name: 'Cazaquistão',
    initials: 'KZ',
    initials_3: 'KAZ',
    id: '398',
  },
  {
    order: 42,
    name: 'Chade',
    initials: 'TD',
    initials_3: 'TCD',
    id: '148',
  },
  {
    order: 43,
    name: 'Chile',
    initials: 'CL',
    initials_3: 'CHL',
    id: '152',
  },
  {
    order: 44,
    name: 'China',
    initials: 'CN',
    initials_3: 'CHN',
    id: '156',
  },
  {
    order: 45,
    name: 'Chipre',
    initials: 'CY',
    initials_3: 'CYP',
    id: '196',
  },
  {
    order: 46,
    name: 'Cingapura',
    initials: 'SG',
    initials_3: 'SGP',
    id: '702',
  },
  {
    order: 47,
    name: 'Colômbia',
    initials: 'CO',
    initials_3: 'COL',
    id: '170',
  },
  {
    order: 48,
    name: 'Congo',
    initials: 'CG',
    initials_3: 'COG',
    id: '178',
  },
  {
    order: 49,
    name: 'Coréia do Norte',
    initials: 'KP',
    initials_3: 'PRK',
    id: '408',
  },
  {
    order: 50,
    name: 'Coréia do Sul',
    initials: 'KR',
    initials_3: 'KOR',
    id: '410',
  },
  {
    order: 51,
    name: 'Costa do Marfim',
    initials: 'CI',
    initials_3: 'CIV',
    id: '384',
  },
  {
    order: 52,
    name: 'Costa Rica',
    initials: 'CR',
    initials_3: 'CRI',
    id: '188',
  },
  {
    order: 53,
    name: 'Croácia (Hrvatska)',
    initials: 'HR',
    initials_3: 'HRV',
    id: '191',
  },
  {
    order: 54,
    name: 'Cuba',
    initials: 'CU',
    initials_3: 'CUB',
    id: '192',
  },
  {
    order: 55,
    name: 'Dinamarca',
    initials: 'DK',
    initials_3: 'DNK',
    id: '208',
  },
  {
    order: 56,
    name: 'Djibuti',
    initials: 'DJ',
    initials_3: 'DJI',
    id: '262',
  },
  {
    order: 57,
    name: 'Dominica',
    initials: 'DM',
    initials_3: 'DMA',
    id: '212',
  },
  {
    order: 58,
    name: 'Egito',
    initials: 'EG',
    initials_3: 'EGY',
    id: '818',
  },
  {
    order: 59,
    name: 'El Salvador',
    initials: 'SV',
    initials_3: 'SLV',
    id: '222',
  },
  {
    order: 60,
    name: 'Emirados Árabes Unidos',
    initials: 'AE',
    initials_3: 'ARE',
    id: '784',
  },
  {
    order: 61,
    name: 'Equador',
    initials: 'EC',
    initials_3: 'ECU',
    id: '218',
  },
  {
    order: 62,
    name: 'Eritréia',
    initials: 'ER',
    initials_3: 'ERI',
    id: '232',
  },
  {
    order: 63,
    name: 'Escócia',
    initials: 'ES',
    initials_3: 'ESC',
    id: '703',
  },
  {
    order: 63,
    name: 'Eslováquia',
    initials: 'SK',
    initials_3: 'SVK',
    id: '703',
  },
  {
    order: 64,
    name: 'Eslovênia',
    initials: 'SI',
    initials_3: 'SVN',
    id: '705',
  },
  {
    order: 65,
    name: 'Espanha',
    initials: 'ES',
    initials_3: 'ESP',
    id: '724',
  },
  {
    order: 66,
    name: 'Estados Unidos',
    initials: 'US',
    initials_3: 'USA',
    id: '840',
  },
  {
    order: 67,
    name: 'Estônia',
    initials: 'EE',
    initials_3: 'EST',
    id: '233',
  },
  {
    order: 68,
    name: 'Etiópia',
    initials: 'ET',
    initials_3: 'ETH',
    id: '231',
  },
  {
    order: 69,
    name: 'Fiji',
    initials: 'FJ',
    initials_3: 'FJI',
    id: '242',
  },
  {
    order: 70,
    name: 'Filipinas',
    initials: 'PH',
    initials_3: 'PHL',
    id: '608',
  },
  {
    order: 71,
    name: 'Finlândia',
    initials: 'FI',
    initials_3: 'FIN',
    id: '246',
  },
  {
    order: 72,
    name: 'França',
    initials: 'FR',
    initials_3: 'FRA',
    id: '250',
  },
  {
    order: 73,
    name: 'Gabão',
    initials: 'GA',
    initials_3: 'GAB',
    id: '266',
  },
  {
    order: 74,
    name: 'Gâmbia',
    initials: 'GM',
    initials_3: 'GMB',
    id: '270',
  },
  {
    order: 75,
    name: 'Gana',
    initials: 'GH',
    initials_3: 'GHA',
    id: '288',
  },
  {
    order: 76,
    name: 'Geórgia',
    initials: 'GE',
    initials_3: 'GEO',
    id: '268',
  },
  {
    order: 77,
    name: 'Gibraltar',
    initials: 'GI',
    initials_3: 'GIB',
    id: '292',
  },
  {
    order: 78,
    name: 'Grã-Bretanha (Reino Unordero, UK)',
    initials: 'GB',
    initials_3: 'GBR',
    id: '826',
  },
  {
    order: 79,
    name: 'Granada',
    initials: 'GD',
    initials_3: 'GRD',
    id: '308',
  },
  {
    order: 80,
    name: 'Grécia',
    initials: 'GR',
    initials_3: 'GRC',
    id: '300',
  },
  {
    order: 81,
    name: 'Groelândia',
    initials: 'GL',
    initials_3: 'GRL',
    id: '304',
  },
  {
    order: 82,
    name: 'Guadalupe',
    initials: 'GP',
    initials_3: 'GLP',
    id: '312',
  },
  {
    order: 83,
    name: 'Guam (Território dos Estados Unidos)',
    initials: 'GU',
    initials_3: 'GUM',
    id: '316',
  },
  {
    order: 84,
    name: 'Guatemala',
    initials: 'GT',
    initials_3: 'GTM',
    id: '320',
  },
  {
    order: 85,
    name: 'Guernsey',
    initials: 'G',
    initials_3: 'GGY',
    id: '832',
  },
  {
    order: 86,
    name: 'Guiana',
    initials: 'GY',
    initials_3: 'GUY',
    id: '328',
  },
  {
    order: 87,
    name: 'Guiana Francesa',
    initials: 'GF',
    initials_3: 'GUF',
    id: '254',
  },
  {
    order: 88,
    name: 'Guiné',
    initials: 'GN',
    initials_3: 'GIN',
    id: '324',
  },
  {
    order: 89,
    name: 'Guiné Equatorial',
    initials: 'GQ',
    initials_3: 'GNQ',
    id: '226',
  },
  {
    order: 90,
    name: 'Guiné-Bissau',
    initials: 'GW',
    initials_3: 'GNB',
    id: '624',
  },
  {
    order: 91,
    name: 'Haiti',
    initials: 'HT',
    initials_3: 'HTI',
    id: '332',
  },
  {
    order: 92,
    name: 'Holanda',
    initials: 'NL',
    initials_3: 'NLD',
    id: '528',
  },
  {
    order: 93,
    name: 'Honduras',
    initials: 'HN',
    initials_3: 'HND',
    id: '340',
  },
  {
    order: 94,
    name: 'Hong Kong',
    initials: 'HK',
    initials_3: 'HKG',
    id: '344',
  },
  {
    order: 95,
    name: 'Hungria',
    initials: 'HU',
    initials_3: 'HUN',
    id: '348',
  },
  {
    order: 96,
    name: 'Iêmen',
    initials: 'YE',
    initials_3: 'YEM',
    id: '887',
  },
  {
    order: 97,
    name: 'Ilha Bouvet (Território da Noruega)',
    initials: 'BV',
    initials_3: 'BVT',
    id: '074',
  },
  {
    order: 98,
    name: 'Ilha do Homem',
    initials: 'IM',
    initials_3: 'IMN',
    id: '833',
  },
  {
    order: 99,
    name: 'Ilha Natal',
    initials: 'CX',
    initials_3: 'CXR',
    id: '162',
  },
  {
    order: 100,
    name: 'Ilha Pitcairn',
    initials: 'PN',
    initials_3: 'PCN',
    id: '612',
  },
  {
    order: 101,
    name: 'Ilha Reunião',
    initials: 'RE',
    initials_3: 'REU',
    id: '638',
  },
  {
    order: 102,
    name: 'Ilhas Aland',
    initials: 'AX',
    initials_3: 'ALA',
    id: '248',
  },
  {
    order: 103,
    name: 'Ilhas Cayman',
    initials: 'KY',
    initials_3: 'CYM',
    id: '136',
  },
  {
    order: 104,
    name: 'Ilhas Cocos',
    initials: 'CC',
    initials_3: 'CCK',
    id: '166',
  },
  {
    order: 105,
    name: 'Ilhas Comores',
    initials: 'KM',
    initials_3: 'COM',
    id: '174',
  },
  {
    order: 106,
    name: 'Ilhas Cook',
    initials: 'CK',
    initials_3: 'COK',
    id: '184',
  },
  {
    order: 107,
    name: 'Ilhas Faroes',
    initials: 'FO',
    initials_3: 'FRO',
    id: '234',
  },
  {
    order: 108,
    name: 'Ilhas Falkland (Malvinas)',
    initials: 'FK',
    initials_3: 'FLK',
    id: '238',
  },
  {
    order: 109,
    name: 'Ilhas Geórgia do Sul e Sandwich do Sul',
    initials: 'GS',
    initials_3: 'SGS',
    id: '239',
  },
  {
    order: 110,
    name: 'Ilhas Heard e McDonald (Território da Austrália)',
    initials: 'HM',
    initials_3: 'HMD',
    id: '334',
  },
  {
    order: 111,
    name: 'Ilhas Marianas do Norte',
    initials: 'MP',
    initials_3: 'MNP',
    id: '580',
  },
  {
    order: 112,
    name: 'Ilhas Marshall',
    initials: 'MH',
    initials_3: 'MHL',
    id: '584',
  },
  {
    order: 113,
    name: 'Ilhas Menores dos Estados Unidos',
    initials: 'UM',
    initials_3: 'UMI',
    id: '581',
  },
  {
    order: 114,
    name: 'Ilhas Norfolk',
    initials: 'NF',
    initials_3: 'NFK',
    id: '574',
  },
  {
    order: 115,
    name: 'Ilhas Seychelles',
    initials: 'SC',
    initials_3: 'SYC',
    id: '690',
  },
  {
    order: 116,
    name: 'Ilhas Solomão',
    initials: 'SB',
    initials_3: 'SLB',
    id: '090',
  },
  {
    order: 117,
    name: 'Ilhas Svalbard e Jan Mayen',
    initials: 'SJ',
    initials_3: 'SJM',
    id: '744',
  },
  {
    order: 118,
    name: 'Ilhas Tokelau',
    initials: 'TK',
    initials_3: 'TKL',
    id: '772',
  },
  {
    order: 119,
    name: 'Ilhas Turks e Caicos',
    initials: 'TC',
    initials_3: 'TCA',
    id: '796',
  },
  {
    order: 120,
    name: 'Ilhas Virgens (Estados Unidos)',
    initials: 'VI',
    initials_3: 'VIR',
    id: '850',
  },
  {
    order: 121,
    name: 'Ilhas Virgens (Inglaterra)',
    initials: 'VG',
    initials_3: 'VGB',
    id: '092',
  },
  {
    order: 122,
    name: 'Ilhas Wallis e Futuna',
    initials: 'WF',
    initials_3: 'WLF',
    id: '876',
  },
  {
    order: 123,
    name: 'índia',
    initials: 'IN',
    initials_3: 'IND',
    id: '356',
  },
  {
    order: 124,
    name: 'Indonésia',
    initials: 'order',
    initials_3: 'orderN',
    id: '360',
  },
  {
    order: 125,
    name: 'Irã',
    initials: 'IR',
    initials_3: 'IRN',
    id: '364',
  },
  {
    order: 126,
    name: 'Iraque',
    initials: 'IQ',
    initials_3: 'IRQ',
    id: '368',
  },
  {
    order: 127,
    name: 'Irlanda',
    initials: 'IE',
    initials_3: 'IRL',
    id: '372',
  },
  {
    order: 127,
    name: 'Irlanda do Norte',
    initials: 'IN',
    initials_3: 'IRN',
    id: '372',
  },
  {
    order: 128,
    name: 'Islândia',
    initials: 'IS',
    initials_3: 'ISL',
    id: '352',
  },
  {
    order: 129,
    name: 'Israel',
    initials: 'IL',
    initials_3: 'ISR',
    id: '376',
  },
  {
    order: 130,
    name: 'Itália',
    initials: 'IT',
    initials_3: 'ITA',
    id: '380',
  },
  {
    order: 131,
    name: 'Jamaica',
    initials: 'JM',
    initials_3: 'JAM',
    id: '388',
  },
  {
    order: 132,
    name: 'Japão',
    initials: 'JP',
    initials_3: 'JPN',
    id: '392',
  },
  {
    order: 133,
    name: 'Jersey',
    initials: 'JE',
    initials_3: 'JEY',
    id: '832',
  },
  {
    order: 134,
    name: 'Jordânia',
    initials: 'JO',
    initials_3: 'JOR',
    id: '400',
  },
  {
    order: 135,
    name: 'Kênia',
    initials: 'KE',
    initials_3: 'KEN',
    id: '404',
  },
  {
    order: 136,
    name: 'Kiribati',
    initials: 'KI',
    initials_3: 'KIR',
    id: '296',
  },
  {
    order: 137,
    name: 'Kosovo',
    initials: 'KO',
    initials_3: 'KOS',
    id: '711',
  },
  {
    order: 137,
    name: 'Kuait',
    initials: 'KW',
    initials_3: 'KWT',
    id: '414',
  },
  {
    order: 138,
    name: 'Laos',
    initials: 'LA',
    initials_3: 'LAO',
    id: '418',
  },
  {
    order: 139,
    name: 'Látvia',
    initials: 'LV',
    initials_3: 'LVA',
    id: '428',
  },
  {
    order: 140,
    name: 'Lesoto',
    initials: 'LS',
    initials_3: 'LSO',
    id: '426',
  },
  {
    order: 140,
    name: 'Letônia',
    initials: 'LE',
    initials_3: 'LET',
    id: '426',
  },
  {
    order: 141,
    name: 'Líbano',
    initials: 'LB',
    initials_3: 'LBN',
    id: '422',
  },
  {
    order: 142,
    name: 'Libéria',
    initials: 'LR',
    initials_3: 'LBR',
    id: '430',
  },
  {
    order: 143,
    name: 'Líbia',
    initials: 'LY',
    initials_3: 'LBY',
    id: '434',
  },
  {
    order: 144,
    name: 'Liechtenstein',
    initials: 'LI',
    initials_3: 'LIE',
    id: '438',
  },
  {
    order: 145,
    name: 'Lituânia',
    initials: 'LT',
    initials_3: 'LTU',
    id: '440',
  },
  {
    order: 146,
    name: 'Luxemburgo',
    initials: 'LU',
    initials_3: 'LUX',
    id: '442',
  },
  {
    order: 147,
    name: 'Macau',
    initials: 'MO',
    initials_3: 'MAC',
    id: '446',
  },
  {
    order: 148,
    name: 'Macedônia (República Yugoslava)',
    initials: 'MK',
    initials_3: 'MKD',
    id: '807',
  },
  {
    order: 149,
    name: 'Madagascar',
    initials: 'MG',
    initials_3: 'MDG',
    id: '450',
  },
  {
    order: 150,
    name: 'Malásia',
    initials: 'MY',
    initials_3: 'MYS',
    id: '458',
  },
  {
    order: 151,
    name: 'Malaui',
    initials: 'MW',
    initials_3: 'MWI',
    id: '454',
  },
  {
    order: 152,
    name: 'Maldivas',
    initials: 'MV',
    initials_3: 'MDV',
    id: '462',
  },
  {
    order: 153,
    name: 'Mali',
    initials: 'ML',
    initials_3: 'MLI',
    id: '466',
  },
  {
    order: 154,
    name: 'Malta',
    initials: 'MT',
    initials_3: 'MLT',
    id: '470',
  },
  {
    order: 155,
    name: 'Marrocos',
    initials: 'MA',
    initials_3: 'MAR',
    id: '504',
  },
  {
    order: 156,
    name: 'Martinica',
    initials: 'MQ',
    initials_3: 'MTQ',
    id: '474',
  },
  {
    order: 157,
    name: 'Maurício',
    initials: 'MU',
    initials_3: 'MUS',
    id: '480',
  },
  {
    order: 158,
    name: 'Mauritânia',
    initials: 'MR',
    initials_3: 'MRT',
    id: '478',
  },
  {
    order: 159,
    name: 'Mayotte',
    initials: 'YT',
    initials_3: 'MYT',
    id: '175',
  },
  {
    order: 160,
    name: 'México',
    initials: 'MX',
    initials_3: 'MEX',
    id: '484',
  },
  {
    order: 161,
    name: 'Micronésia',
    initials: 'FM',
    initials_3: 'FSM',
    id: '583',
  },
  {
    order: 162,
    name: 'Moçambique',
    initials: 'MZ',
    initials_3: 'MOZ',
    id: '508',
  },
  {
    order: 163,
    name: 'Moldova',
    initials: 'MD',
    initials_3: 'MDA',
    id: '498',
  },
  {
    order: 164,
    name: 'Mônaco',
    initials: 'MC',
    initials_3: 'MCO',
    id: '492',
  },
  {
    order: 165,
    name: 'Mongólia',
    initials: 'MN',
    initials_3: 'MNG',
    id: '496',
  },
  {
    order: 166,
    name: 'Montenegro',
    initials: 'ME',
    initials_3: 'MNE',
    id: '499',
  },
  {
    order: 167,
    name: 'Montserrat',
    initials: 'MS',
    initials_3: 'MSR',
    id: '500',
  },
  {
    order: 168,
    name: 'Myanma',
    initials: 'MM',
    initials_3: 'MMR',
    id: '104',
  },
  {
    order: 169,
    name: 'Namíbia',
    initials: 'NA',
    initials_3: 'NAM',
    id: '516',
  },
  {
    order: 170,
    name: 'Nauru',
    initials: 'NR',
    initials_3: 'NRU',
    id: '520',
  },
  {
    order: 171,
    name: 'Nepal',
    initials: 'NP',
    initials_3: 'NPL',
    id: '524',
  },
  {
    order: 172,
    name: 'Nicarágua',
    initials: 'NI',
    initials_3: 'NIC',
    id: '558',
  },
  {
    order: 173,
    name: 'Níger',
    initials: 'NE',
    initials_3: 'NER',
    id: '562',
  },
  {
    order: 174,
    name: 'Nigéria',
    initials: 'NG',
    initials_3: 'NGA',
    id: '566',
  },
  {
    order: 175,
    name: 'Niue',
    initials: 'NU',
    initials_3: 'NIU',
    id: '570',
  },
  {
    order: 176,
    name: 'Noruega',
    initials: 'NO',
    initials_3: 'NOR',
    id: '578',
  },
  {
    order: 177,
    name: 'Nova Caledônia',
    initials: 'NC',
    initials_3: 'NCL',
    id: '540',
  },
  {
    order: 178,
    name: 'Nova Zelândia',
    initials: 'NZ',
    initials_3: 'NZL',
    id: '554',
  },
  {
    order: 179,
    name: 'Omã',
    initials: 'OM',
    initials_3: 'OMN',
    id: '512',
  },
  {
    order: 180,
    name: 'Palau',
    initials: 'PW',
    initials_3: 'PLW',
    id: '585',
  },
  {
    order: 181,
    name: 'Panamá',
    initials: 'PA',
    initials_3: 'PAN',
    id: '591',
  },
  {
    order: 182,
    name: 'Papua-Nova Guiné',
    initials: 'PG',
    initials_3: 'PNG',
    id: '598',
  },
  {
    order: 183,
    name: 'Paquistão',
    initials: 'PK',
    initials_3: 'PAK',
    id: '586',
  },
  {
    order: 184,
    name: 'Paraguai',
    initials: 'PY',
    initials_3: 'PRY',
    id: '600',
  },
  {
    order: 185,
    name: 'Peru',
    initials: 'PE',
    initials_3: 'PER',
    id: '604',
  },
  {
    order: 186,
    name: 'Polinésia Francesa',
    initials: 'PF',
    initials_3: 'PYF',
    id: '258',
  },
  {
    order: 187,
    name: 'Polônia',
    initials: 'PL',
    initials_3: 'POL',
    id: '616',
  },
  {
    order: 188,
    name: 'Porto Rico',
    initials: 'PR',
    initials_3: 'PRI',
    id: '630',
  },
  {
    order: 189,
    name: 'Portugal',
    initials: 'PT',
    initials_3: 'PRT',
    id: '620',
  },
  {
    order: 190,
    name: 'Qatar',
    initials: 'QA',
    initials_3: 'QAT',
    id: '634',
  },
  {
    order: 191,
    name: 'Quirguistão',
    initials: 'KG',
    initials_3: 'KGZ',
    id: '417',
  },
  {
    order: 192,
    name: 'República Centro-Africana',
    initials: 'CF',
    initials_3: 'CAF',
    id: '140',
  },
  {
    order: 193,
    name: 'República Democrática do Congo',
    initials: 'CD',
    initials_3: 'COD',
    id: '180',
  },
  {
    order: 194,
    name: 'República Dominicana',
    initials: 'DO',
    initials_3: 'DOM',
    id: '214',
  },
  {
    order: 195,
    name: 'República Tcheca',
    initials: 'CZ',
    initials_3: 'CZE',
    id: '203',
  },
  {
    order: 196,
    name: 'Romênia',
    initials: 'RO',
    initials_3: 'ROM',
    id: '642',
  },
  {
    order: 197,
    name: 'Ruanda',
    initials: 'RW',
    initials_3: 'RWA',
    id: '646',
  },
  {
    order: 198,
    name: 'Rússia (antiga URSS) - Federação Russa',
    initials: 'RU',
    initials_3: 'RUS',
    id: '643',
  },
  {
    order: 199,
    name: 'Saara Ocorderental',
    initials: 'EH',
    initials_3: 'ESH',
    id: '732',
  },
  {
    order: 200,
    name: 'Saint Vincente e Granadinas',
    initials: 'VC',
    initials_3: 'VCT',
    id: '670',
  },
  {
    order: 201,
    name: 'Samoa Americana',
    initials: 'AS',
    initials_3: 'ASM',
    id: '016',
  },
  {
    order: 202,
    name: 'Samoa Ocorderental',
    initials: 'WS',
    initials_3: 'WSM',
    id: '882',
  },
  {
    order: 203,
    name: 'San Marino',
    initials: 'SM',
    initials_3: 'SMR',
    id: '674',
  },
  {
    order: 204,
    name: 'Santa Helena',
    initials: 'SH',
    initials_3: 'SHN',
    id: '654',
  },
  {
    order: 205,
    name: 'Santa Lúcia',
    initials: 'LC',
    initials_3: 'LCA',
    id: '662',
  },
  {
    order: 206,
    name: 'São Bartolomeu',
    initials: 'BL',
    initials_3: 'BLM',
    id: '652',
  },
  {
    order: 207,
    name: 'São Cristóvão e Névis',
    initials: 'KN',
    initials_3: 'KNA',
    id: '659',
  },
  {
    order: 208,
    name: 'São Martim',
    initials: 'MF',
    initials_3: 'MAF',
    id: '663',
  },
  {
    order: 209,
    name: 'São Tomé e Príncipe',
    initials: 'ST',
    initials_3: 'STP',
    id: '678',
  },
  {
    order: 210,
    name: 'Senegal',
    initials: 'SN',
    initials_3: 'SEN',
    id: '686',
  },
  {
    order: 211,
    name: 'Serra Leoa',
    initials: 'SL',
    initials_3: 'SLE',
    id: '694',
  },
  {
    order: 212,
    name: 'Sérvia',
    initials: 'RS',
    initials_3: 'SRB',
    id: '688',
  },
  {
    order: 213,
    name: 'Síria',
    initials: 'SY',
    initials_3: 'SYR',
    id: '760',
  },
  {
    order: 214,
    name: 'Somália',
    initials: 'SO',
    initials_3: 'SOM',
    id: '706',
  },
  {
    order: 215,
    name: 'Sri Lanka',
    initials: 'LK',
    initials_3: 'LKA',
    id: '144',
  },
  {
    order: 216,
    name: 'St. Pierre and Miquelon',
    initials: 'PM',
    initials_3: 'SPM',
    id: '666',
  },
  {
    order: 217,
    name: 'Suazilândia',
    initials: 'SZ',
    initials_3: 'SWZ',
    id: '748',
  },
  {
    order: 218,
    name: 'Sudão',
    initials: 'SD',
    initials_3: 'SDN',
    id: '736',
  },
  {
    order: 219,
    name: 'Suécia',
    initials: 'SE',
    initials_3: 'SWE',
    id: '752',
  },
  {
    order: 220,
    name: 'Suíça',
    initials: 'CH',
    initials_3: 'CHE',
    id: '756',
  },
  {
    order: 221,
    name: 'Suriname',
    initials: 'SR',
    initials_3: 'SUR',
    id: '740',
  },
  {
    order: 222,
    name: 'Tadjiquistão',
    initials: 'TJ',
    initials_3: 'TJK',
    id: '762',
  },
  {
    order: 223,
    name: 'Tailândia',
    initials: 'TH',
    initials_3: 'THA',
    id: '764',
  },
  {
    order: 224,
    name: 'Taiwan',
    initials: 'TW',
    initials_3: 'TWN',
    id: '158',
  },
  {
    order: 225,
    name: 'Tanzânia',
    initials: 'TZ',
    initials_3: 'TZA',
    id: '834',
  },
  {
    order: 226,
    name: 'Território Britânico do Oceano índico',
    initials: 'IO',
    initials_3: 'IOT',
    id: '086',
  },
  {
    order: 227,
    name: 'Territórios do Sul da França',
    initials: 'TF',
    initials_3: 'ATF',
    id: '260',
  },
  {
    order: 228,
    name: 'Territórios Palestinos Ocupados',
    initials: 'PS',
    initials_3: 'PSE',
    id: '275',
  },
  {
    order: 229,
    name: 'Timor Leste',
    initials: 'TP',
    initials_3: 'TMP',
    id: '626',
  },
  {
    order: 230,
    name: 'Togo',
    initials: 'TG',
    initials_3: 'TGO',
    id: '768',
  },
  {
    order: 231,
    name: 'Tonga',
    initials: 'TO',
    initials_3: 'TON',
    id: '776',
  },
  {
    order: 232,
    name: 'Trinorderad and Tobago',
    initials: 'TT',
    initials_3: 'TTO',
    id: '780',
  },
  {
    order: 233,
    name: 'Tunísia',
    initials: 'TN',
    initials_3: 'TUN',
    id: '788',
  },
  {
    order: 234,
    name: 'Turcomenistão',
    initials: 'TM',
    initials_3: 'TKM',
    id: '795',
  },
  {
    order: 235,
    name: 'Turquia',
    initials: 'TR',
    initials_3: 'TUR',
    id: '792',
  },
  {
    order: 236,
    name: 'Tuvalu',
    initials: 'TV',
    initials_3: 'TUV',
    id: '798',
  },
  {
    order: 237,
    name: 'Ucrânia',
    initials: 'UA',
    initials_3: 'UKR',
    id: '804',
  },
  {
    order: 238,
    name: 'Uganda',
    initials: 'UG',
    initials_3: 'UGA',
    id: '800',
  },
  {
    order: 239,
    name: 'Uruguai',
    initials: 'UY',
    initials_3: 'URY',
    id: '858',
  },
  {
    order: 240,
    name: 'Uzbequistão',
    initials: 'UZ',
    initials_3: 'UZB',
    id: '860',
  },
  {
    order: 241,
    name: 'Vanuatu',
    initials: 'VU',
    initials_3: 'VUT',
    id: '548',
  },
  {
    order: 242,
    name: 'Vaticano',
    initials: 'VA',
    initials_3: 'VAT',
    id: '336',
  },
  {
    order: 243,
    name: 'Venezuela',
    initials: 'VE',
    initials_3: 'VEN',
    id: '862',
  },
  {
    order: 244,
    name: 'Vietnã',
    initials: 'VN',
    initials_3: 'VNM',
    id: '704',
  },
  {
    order: 245,
    name: 'Zâmbia',
    initials: 'ZM',
    initials_3: 'ZMB',
    id: '894',
  },
  {
    order: 246,
    name: 'Zimbábue',
    initials: 'ZW',
    initials_3: 'ZWE',
    id: '716',
  },
  
];
