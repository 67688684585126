import yup from '~/libs/yup';

// 1024 * 1024 = 1048576 bytes equivalente a 1 mb
const FILE_SIZE = 1024 * 1024;
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

export const createSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  initials: yup.string().required().label('Sigla'),
  shield: yup
    .mixed()
    .required('A imagem é obrigatória')
    .test(
      'fileSize',
      `O arquivo não pode ser menor que ${FILE_SIZE / 1048576} Mb`,
      (files) => {
        const file = files[0];
        return file && file.size <= FILE_SIZE;
      }
    )
    .test(
      'fileFormat',
      'Os formatos suportados são jpg, jpeg e png',
      (files) => {
        const file = files[0];
        return file && SUPPORTED_FORMATS.includes(file.type);
      }
    ),
  address: yup.object().shape({
    state: yup.string().when('country', {
      is: 'Brasil',
      then: yup.string().required().label('Estado'),
    }),
    country: yup.string().required().label('País'),
  }),
});

export const updateSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  initials: yup.string().required().label('Sigla'),
  shield: yup
    .mixed()
    .test(
      'fileSize',
      `O arquivo não pode ser menor que ${FILE_SIZE / 1048576} Mb`,
      (files) => {
        if (files === undefined) return true;

        const file = files[0];
        return file && file.size <= FILE_SIZE;
      }
    )
    .test(
      'fileFormat',
      'Os formatos suportados são jpg, jpeg e png',
      (files) => {
        if (files === undefined) return true;

        const file = files[0];
        return file && SUPPORTED_FORMATS.includes(file.type);
      }
    ),
  address: yup.object().shape({
    state: yup.string().when('country', {
      is: 'Brasil',
      then: yup.string().required().label('Estado'),
    }),
    country: yup.string().required().label('País'),
  }),
});
