import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 320px;

  .root-form {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    svg {
      margin-bottom: 45px;
    }

    .root-input {
      margin-bottom: 15px;
    }

    .root-button {
      margin-top: 15px;
    }
  }
`;
