import React, { useCallback, useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import Pagination from '~/components/Pagination';
import Table from '~/components/Table';
import useIsMountedRef from '~/hooks/useIsMountedRef';
import api from '~/services/api';
import masks from '~/utils/masks';

import { Container, Content, Head, Title, Pending } from './styles';
import ControlsTabs from '~/pages/Round/View/ControlsTabs';
import { useParams } from 'react-router-dom';

const INITIAL_BETS = {
  data: [],
  page: 0,
  limit: 0,
  totalData: 0,
  totalPages: 0,
};

const List = () => {
  const isMountedRef = useIsMountedRef();

  const { id } = useParams();

  const [bets, setBets] = useState(INITIAL_BETS);
  const [round, setRound] = useState({
    name: '',
    games: [],
  });
  const [loading, setLoading] = useState(true);

  const getBets = useCallback(async (page = 1) => {
    try {
      const { data } = await api.get(`/bet?page=${page}&perPage=20&sort=-status -created_at&round=${id}`);
      
      setBets(data);
    } catch (error) {
      toast.error('Ocorreu um erro ao buscar as apostas...');
      setBets(INITIAL_BETS);
    } finally {
      setLoading(false);
    }
  }, []);

  const getRound = useCallback(async () => {
    try {
      const { data } = await api.get(`/round/${id}`);

      setRound(data);
    } catch (error) {
      toast.error('Ocorreu um erro ao buscar a rodada...');
      setRound([]);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (isMountedRef) {
      getRound();
      getBets();

    }
  }, [isMountedRef, getBets, getRound]);

  const handleChangePage = (page) => setBets(page);

  const columns = [
    {
      key: ['name', 'status'],
      label: 'Apostador',
      format: ({ name, status }) => [status == 'P' && <Pending>&nbsp;</Pending>, name],
    },
    {
      key: 'code',
      label: '# Aposta'
    },
    {
      key: 'created_by.name',
      label: 'Vendedor',
    },
    {
      key: 'round_manager.groups.name',
      label: 'Grupo',
    }
  ];

  return (
    <Container>
      <Head>
        <Title>Rodadas - {round?.name}</Title>
      </Head>
      <ControlsTabs availableForBet={round?.availableForBet || true} getRound={getRound} active="Apostas"  roundId={id} round={round}/>
      <Content>
        <Table
          columns={columns}
          rows={bets.data}
          loading={loading}
          preLoadRows={10}
        />
        <Pagination
          onChange={getBets}
          page={bets.page}
          pageSize={bets.limit}
          total={bets.totalData}
          totalPages={bets.totalPages}
          position="flex-end"
        />
      </Content>
    </Container>
  );
};

export default List;
