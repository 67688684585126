import yup from '~/libs/yup';

export const consultSchema = yup.object().shape({
  code: yup.string().length(7).required().label('Código da aposta'),
});

export const createBet = yup.object().shape({
  name: yup.string().required().label('Nome'),
  phone: yup.string().required().label('Telefone'),
  document: yup.string(),
  payment: yup.string().required().label('Forma de pagamento'),
  round_id: yup.string().length(24).required(),
  bets: yup
    .array()
    .of(
      yup.object().shape({
        result: yup.string().oneOf(['tie', 'host', 'guest']).required(),
        game_id: yup.string().length(24).required(),
      })
    )
    .required(),
});