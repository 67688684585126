import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useRef,
} from 'react';

import formatDateFns from 'date-fns/format';
import ptLocaleDateFns from 'date-fns/locale/pt';
import { toast } from 'react-toastify';

import GroupInput from '~/components/GroupInput';
import Input from '~/components/Input';
import Select from '~/components/Select';
import Modal from '~/components/Modal';
import useModal from '~/hooks/useModal';
import api from '~/services/api';
import masks from '~/utils/masks';
import yupValidate from '~/utils/yupValidate';
import { createSchema } from '~/validators/round.schema';
import { Subtitle } from '../styles';

import SelectManagers from './SelectManagers';

const ModalForm = ({ ...rest }, ref) => {
  const formRef = useRef(null);

  const { isOpen, closeModal, openModal } = useModal();

  const [initialData, setInitialData] = useState({});

  useImperativeHandle(ref, () => ({
    openModal: (data = {}) => {
      setInitialData(data);
      openModal();
    },
  }));

  const handleSubmit = async ({
    award,
    commission_admin,
    commission_manager,
    commission_sub_manager,
    commission_seller,
    value_bet,
    min_award,
    managers,
    ...restFormData
  }) => {
    try {

      formRef.current.setErrors({});

      const valueBet = masks.unmask(value_bet);
      const minAward = masks.unmask(min_award);
      const managersIds = managers.map((id) => ({ id }));

      const { success, data, errors } = await yupValidate(createSchema, {
        award,
        commission_admin,
        commission_manager,
        commission_sub_manager,
        commission_seller,
        ...restFormData,
        managers: managersIds,
        value_bet: valueBet,
        min_award: minAward
      });

      if (!success) {
        return formRef.current.setErrors(errors);
      }

      const awardFormatted = Number(award);
      const commissionAdminFormatted = Number(commission_admin);
      const commissionManagerFormatted = Number(commission_manager);
      const commissionSubManagerFormatted = Number(commission_sub_manager);
      const commissionSellerFormatted = Number(commission_seller);

      const sumCommission =
        awardFormatted +
        commissionAdminFormatted +
        commissionManagerFormatted +
        commissionSubManagerFormatted +
        commissionSellerFormatted;

      if (sumCommission !== 100) {
        return formRef.current.setFieldError(
          'commission_seller',
          'A soma das comissões junto com o prêmio deve ser igual a 100%'
        );
      }

      // Dados básicos
      const {
        data: { _id },
      } = await api.post('/round', data);

      closeModal();

      toast.success('Rodada cadastrada com sucesso!');

      if (rest.onSave) rest.onSave();

      return _id;
    } catch (error) {
      if (error.response) {
        const { status, data } = error.response;

        if (status === 400) {
          return toast.error(
            `Ocorreram erros na validação... Erros: ${data.error.join('')}`
          );
        }
      }

      return toast.error(`Ocorreu um erro ao tentar cadastrar a rodada...`);
    }
  };

  return (
    <Modal
      formRef={formRef}
      title="Nova rodada"
      isOpen={isOpen}
      closeModal={closeModal}
      onSubmit={handleSubmit}
      initialData={initialData}
      maxWidth={600}
    >
      <Input label="Nome" name="name" variant="border" />
      <GroupInput gap={15} columns={['3fr', '1fr']}>
        <Input label="Região" name="region" variant="border" />
        <Input
          type="date"
          label="Inicia em"
          name="start"
          variant="border"
          defaultValue={formatDateFns(new Date(), 'yyyy-MM-dd', {
            locale: ptLocaleDateFns,
          })}
        />
      </GroupInput>
      <GroupInput gap={15} columns={['1fr', '2fr', '2fr']}>
        <Input
          label="Jogos"
          name="max_games"
          type="number"
          min="1"
          max="100"
          variant="border"
          fontStyle="regular"
          defaultValue={10}
        />
        <Select
          label="Opções"
          name="has_tie"
          defaultValue={true}
          options={[
            { label: 'Casa | Empate | Fora', value: true },
            { label: 'Casa | Fora', value: false }
          ]}
        />
        <Input
          label="Prêmio mínimo"
          name="min_award"
          mask="currency"
          variant="border"
          fontStyle="regular"
        />
      </GroupInput>
      <Subtitle>Apostas</Subtitle>
      <GroupInput gap={15} columns="repeat(3, 1fr)">
        <Input
          label="Máximo"
          name="max_bets"
          type="number"
          variant="border"
          fontStyle="regular"
          defaultValue={100000}
        />
        <Input
          label="Valor"
          name="value_bet"
          mask="currency"
          variant="border"
          fontStyle="regular"
        />
        <Input
          label="Prêmio %"
          name="award"
          type="number"
          min="0"
          max="100"
          variant="border"
          fontStyle="regular"
        />
      </GroupInput>

      <Subtitle>Comissões</Subtitle>
      <GroupInput gap={15} columns="repeat(2, 1fr)">
        <Input
          label="Administrador (%)"
          name="commission_admin"
          type="number"
          min="0"
          max="100"
          variant="border"
          fontStyle="regular"
        />
        <Input
          label="Gerente (%)"
          name="commission_manager"
          type="number"
          min="0"
          max="100"
          variant="border"
          fontStyle="regular"
        />
        <Input
          label="Subgerente (%)"
          name="commission_sub_manager"
          type="number"
          min="0"
          max="100"
          variant="border"
          fontStyle="regular"
        />
        <Input
          label="Vendedor (%)"
          name="commission_seller"
          type="number"
          min="0"
          max="100"
          variant="border"
          fontStyle="regular"
        />
      </GroupInput>

      <SelectManagers />
    </Modal>
  );
};

export default forwardRef(ModalForm);
