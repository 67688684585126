import React, { useCallback, useState } from 'react';

import { Scope } from '@unform/core';
import PropTypes from 'prop-types';

import Select from '~/components/Select';
import countries from '~/utils/countries';
import states from '~/utils/states';

const Address = ({ defaultCountry }) => {
  const [country, setCountry] = useState(defaultCountry);

  const handleChangeCountry = useCallback(({ value }) => {
    setCountry(value);
  }, []);

  return (
    <Scope path="address">
      <Select
        label="País"
        name="country"
        variant="border"
        options={countries.map(({ name }) => ({
          label: name,
          value: name,
        }))}
        onChange={handleChangeCountry}
        isSearchable
      />
      {country === 'Brasil' && (
        <Select
          label="Estado"
          name="state"
          variant="border"
          options={states.map(({ name }) => ({
            label: name,
            value: name,
          }))}
          isSearchable
        />
      )}
    </Scope>
  );
};

Address.propTypes = {
  defaultCountry: PropTypes.string.isRequired,
};

export default Address;
