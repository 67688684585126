import { useContext } from 'react';

import ModalContext from '~/components/ModalDelete/ModalContext';

const useModalDelete = () => {
  const { open } = useContext(ModalContext);

  return {
    openModalDelete: open,
  };
};

export default useModalDelete;
