import styled from 'styled-components';

// import devices from '~/styles/configs/devices';
import { Container as Card } from '~/components/Card/styles';

export const Container = styled.div`
  padding: 30px;

  max-width: 600px;

  margin: 0 auto;
`;

export const Content = styled(Card)`
  padding: 60px;

  .root-input {
    margin-bottom: 15px;
  }
`;

export const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-bottom: 30px;
`;

export const Title = styled.div`
  font-size: 24px;
  color: #ffffff;
`;
