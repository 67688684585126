import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const ActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  border: none;

  height: 28px;
  width: 28px;
  border-radius: 4px;

  &:not(:last-of-type) {
    margin-right: 15px;
  }

  svg {
    pointer-events: none;

    font-size: 20px;
  }
`;

export const DeleteButton = styled(ActionButton)`
  background-color: #ff3b30;

  svg {
    color: #f5f5f5;
  }
`;

export const EditButton = styled(ActionButton)`
  background-color: #90ca28;

  svg {
    color: #ffffff;
  }
`;

export const ViewButton = styled(ActionButton)`
  background-color: #90ca28;

  svg {
    color: #ffffff;
  }
`;
