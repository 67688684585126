import styled from 'styled-components';

export const Container = styled.div`
  display: inline-block;

  border-radius: 8px;
  padding: 15px;
`;

export const Label = styled.div`
  color: #ffffff;
  font-size: 14px;
  margin-bottom: 7px;
`;

export const Value = styled.div`
  color: #d9bc3e;
  font-size: 24px;
  font-weight: 500;
`;
