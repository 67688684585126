import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  padding: 0 15px 0 30px;

  background-color: #90ca28;
`;

export const Avatar = styled.div`
  width: 40px;
  height: 40px;

  background-color: #f5f5f5;
  background-image: ${({ src }) => `url('${src}')`};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  border-radius: 50%;
`;

export const Initials = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;

  border-radius: 50%;

  color: #ffffff;
  font-size: 16px;

  background-color: #729e23;
`;

export const Info = styled.div`
  margin: 0 30px 0 15px;
`;

export const Name = styled.div`
  font-size: 16px;
  font-weight: 500;

  color: #ffffff;
`;

export const Role = styled.div`
  font-size: 14px;

  color: #eeeeee;
`;
