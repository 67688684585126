/* eslint-disable react/require-default-props */
import React, { useState } from 'react';

import differenceInHours from 'date-fns/differenceInHours';
import format from 'date-fns/format';
import parseJSON from 'date-fns/parseJSON';
import PropTypes from 'prop-types';
import FadeIn from 'react-fade-in';
import { MdEdit, MdDelete } from 'react-icons/md';

import Button from '~/components/Button';
import TeamAvatar from '~/components/TeamAvatar';
import useModalDelete from '~/hooks/useModalDelete';
import api from '~/services/api';

import {
	Container,
	Header,
	Championship,
	TeamsContainer,
	TeamResult,
	TeamName,
	VsContainer,
	VsText,
	VsResult,
	GameDate,
	Actions,
	EditButton,
	DeleteButton,
} from './styles';

const CardGame = ({ data, canEdit, onEdit, onSave, onSetResult, onDelete, setResult = false, hasTie = true, showButton = true }) => {
	const { _id, date, result, championship, team_host, team_guest } = data;

	const { openModalDelete } = useModalDelete();

	const dateFormatted = new Date(date);
	const currentDate = new Date();

	const availableToSetResult =
		differenceInHours(currentDate, dateFormatted) >= 2 || setResult;

	const [gameResult, setGameResult] = useState(result);

	const handleSelectResult = async (result) => {
		try {
			if(!availableToSetResult || (result == 'tie' && !hasTie)) return

			if(gameResult == result) result = ''
			
			await onSetResult(_id, result)

			setGameResult(result);

			if(onSave) onSave();
		} catch (error) {
			console.log('>>> error', error);
		}
	};

	const handleClickEdit = () =>
		onEdit({
			...data,
			team_host_id: team_host._id,
			team_guest_id: team_guest._id,
		});

	const handleClickDelete = async () => {
		try {
			openModalDelete({
				url: `/game/${_id}`,
				title: 'Deseja deletar este jogo?',
				onDelete,
				autoClose: true,
			});

			// const { status } = await api.delete(`/team/${value}`);
		} catch (error) {
			console.log('>>> error', error);
		}
	};

	return (
		<FadeIn>
			<Container availableToSetResult={availableToSetResult && !gameResult}>
				<Header>
					<Championship>{championship}</Championship>
					{canEdit && (
						<>
							{!availableToSetResult && canEdit.game && (
								<Actions>
									<EditButton type="button" onClick={handleClickEdit}>
										<MdEdit />
									</EditButton>
									<DeleteButton type="button" onClick={handleClickDelete}>
										<MdDelete />
									</DeleteButton>
								</Actions>
							)}
							{gameResult && canEdit.result && (
								<Actions>
									<EditButton type="button" onClick={() => setGameResult('')}>
										<MdEdit />
									</EditButton>
									<DeleteButton type="button" onClick={handleClickDelete}>
										<MdDelete />
									</DeleteButton>
								</Actions>
							)}
						</>
					)}
				</Header>

				<TeamsContainer columns={['1fr', '*', '1fr']}>
					<TeamResult result={gameResult} type={'host'} onClick={() => handleSelectResult('host')}>
						<TeamAvatar src={team_host.shield} size={80} />
						<TeamName>{team_host.name}</TeamName>
						{availableToSetResult && showButton && !gameResult && (
							<Button
								label="Vencedor"
								color="invert-border"
							/>
						)}
					</TeamResult>
					<VsContainer result={gameResult} onClick={() => handleSelectResult('tie')}>
						<VsText>VS</VsText>
						{gameResult === 'tie' && <VsResult>Empate</VsResult>}
						{availableToSetResult && hasTie && !gameResult && (
							showButton ? 
								<Button
									label="Empate"
									color="invert-border"
								/>:<span>Empate</span>
						)}
					</VsContainer>
					<TeamResult result={gameResult} type={'guest'} onClick={() => handleSelectResult('guest')}>
						<TeamAvatar src={team_guest.shield} size={80} />
						<TeamName>{team_guest.name}</TeamName>
						{availableToSetResult && showButton && !gameResult && (
							<Button
								label="Vencedor"
								color="invert-border"
							/>
						)}
					</TeamResult>
				</TeamsContainer>
				<GameDate>
					{format(dateFormatted, "dd/MM/yyyy 'às' H:mm 'hrs'")}
				</GameDate>
			</Container>
		</FadeIn>
	);
};

CardGame.propTypes = {
	onSave: PropTypes.func,
	onEdit: PropTypes.func,
	onDelete: PropTypes.func,
	onSetResult: PropTypes.func,
	setResult: PropTypes.bool,
	hasTie: PropTypes.bool,
	showButton: PropTypes.bool,
	data: PropTypes.shape({
		_id: PropTypes.string,
		result: PropTypes.string,
		championship: PropTypes.string,
		date: PropTypes.string,
		team_host: PropTypes.shape({
			_id: PropTypes.string,
			shield: PropTypes.string,
			name: PropTypes.string,
		}),
		team_guest: PropTypes.shape({
			_id: PropTypes.string,
			shield: PropTypes.string,
			name: PropTypes.string,
		}),
	}).isRequired,
};

export default CardGame;
