import styled, { css } from 'styled-components';

import ripple from '~/styles/animations/ripple';

export const Container = styled.div`
  position: relative;
  overflow: hidden;

  border-radius: 8px;

  border: 1px solid #151a5d;
  background-color: #222e3b;

  ${({ availableToSetResult }) =>
    availableToSetResult &&
    css`
      border-color: #90ca28;
    `}
`;

export const Header = styled.div`
  position: relative;

  display: flex;
  justify-content: center;

  padding: 15px;
  background-color: #192129;
`;

export const Championship = styled.div`
  font-size: 18px;
  color: #ffffff;
`;

export const TeamsContainer = styled.div`
  display: grid;
  grid-template-columns: 10fr 1fr 10fr;

  justify-content: center;

  padding: 20px;

  button {
    margin-top: 10px;
    padding: 5px 7px;
  }
`;

export const TeamProfile = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  &:after {
    display: none;
    content: 'Vencedor';
    position: absolute;
    bottom: 0;
    left: 50%;

    padding: 5px;
    border-radius: 4px;

    transform: translate(-50%, +100%);

    font-size: 12px;
    color: #90ca28;
  }
`;

export const TeamHostProfile = styled(TeamProfile)`
  ${({ result }) => {
    if (result === 'guest' || result === 'tie')
      return css`
        color: #90ca28
        .root-team-avatar {
          filter: grayscale(100%);
        }
      `;

    if (result === 'host') {
      return css`
        &:after {
          display: block;
        }
      `;
    }

    return css``;
  }}
`;

export const TeamResult = styled(TeamProfile)`
  ${({ result, type }) => {
    if(!result) {
      return css`color: #fff`
    }else if (result === type) {
      return css`
        color: #fff;
        &:after {
          display: block;
        }
      `;
    }else {
      return css`
        .root-team-avatar {
          filter: grayscale(100%);
          opacity: 50%;
        }
      `;
    }
  }}
`;

export const TeamName = styled.div`
  width: 100%;
  font-size: 16px;

  margin-top: 5px;
  text-align: center;
`;

export const VsContainer = styled.div`
  margin: 0 15px;
  align-self: center;
  text-align: center;

  ${({ result }) => {
    if (!result) {
      return css`
        color: #fff
      `;
    }else if (result === 'tie') {
      return css`
        color: #90ca28
      `;
    }else return css`
      color: #192129;
    `;
  }}
`;

export const VsText = styled.div`
  font-weight: 900;
  font-size: 32px;
`;

export const VsResult = styled.div`
  font-weight: 500;
  font-size: 20px;
`;

export const GameDate = styled.div`
  color: #ffffff;
  font-size: 16px;

  text-align: center;

  padding-bottom: 30px;
`;

export const Actions = styled.div`
  position: absolute;

  right: 15px;

  display: flex;
  align-items: center;
`;

export const ActionButton = styled.button`
  ${ripple};

  display: flex;
  align-items: center;
  justify-content: center;

  border: none;

  height: 28px;
  width: 28px;
  border-radius: 4px;

  &:not(:last-of-type) {
    margin-right: 10px;
  }

  svg {
    pointer-events: none;

    font-size: 20px;
  }
`;

export const EditButton = styled(ActionButton)`
  background-color: #90ca28;

  svg {
    color: #ffffff;
  }
`;

export const DeleteButton = styled(ActionButton)`
  background-color: #ff3b30;

  svg {
    color: #ffffff;
  }
`;
