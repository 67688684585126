import styled from 'styled-components';

import devices, { sizes } from '~/styles/configs/devices';

export const Container = styled.div`
  padding: 30px;
`;

export const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-bottom: 30px;
`;

export const Title = styled.div`
  font-size: 24px;
  color: #ffffff;
`;

export const ListGames = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;

  @media ${`(max-width: ${sizes.tablet})`} {
    grid-template-columns: 1fr;
  }
`;

export const Message = styled.div`
  font-size: 16px;
  color: #ffffff;
`;

export const MessageHighlight = styled.span`
  font-weight: 500;
  color: #90ca28;
`;
