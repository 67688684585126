import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 30px;

  height: 100%;
  overflow-y: scroll;

  background-color: #0f113e;
`;
 
export const Content = styled.div``;

export const Head = styled.div`
  display: block;
  color: #ffffff;
  font-size: 14px;
  margin-bottom: 30px;
`;

export const Seller = styled.div`
  color: #90ca28;
  margin: 10px 0;
`;

export const Pending = styled.div`
  color: #ed7014;
  font-size: 18px;
  font-weight: 900;
  margin-top: 20px;
`;

export const Price = styled.div`
  font-size: 50px;
  font-weight: 900;
  margin-top: 10px;
`

export const Title = styled.div`
  font-size: 32px;
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 30px;

  @media (max-width: 992px) {
    display: block
  }
`;

export const Legend = styled.div`
  display: flex;

  padding: 30px 0;
`;

export const HitContainer = styled.div`
  display: flex;
  align-items: center;

  margin-right: 30px;
`;

export const HitLegend = styled.div`
  color: #ffffff;
  font-size: 16px;
`;

export const Hit = styled.div`
  margin-right: 10px;

  height: 20px;
  width: 20px;

  border-radius: 50%;

  ${({ hit }) =>
    hit
      ? css`
          background-color: #90ca28;
        `
      : css`
          background-color: #ff3b30;
        `}
`;

export const ListGames = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  margin-bottom: 40px;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;
