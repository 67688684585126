import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Table from '~/components/Table';
import api from '~/services/api';

import { Container, Title } from './styles';

const TableRanking = () => {
	const INITIAL_USERS = {
		data: [],
		page: 0,
		limit: 0,
		totalData: 0,
		totalPages: 0,
	};

	const columns = [
		{
			key: 'name',
			label: 'Vendedores',
		},
		{
			key: 'qtd-sells',
			label: 'Quantidade de vendas',
		},
	];

	const [users, setUsers] = useState(INITIAL_USERS);

	const [loading, setLoading] = useState(true);

	const getUsers = useCallback(async (page = 1) => {
		try {
			const { data } = await api.get(`/user?page=${page}&perPage=10`);

			setUsers(data);
		} catch (error) {
			setUsers(INITIAL_USERS);

			toast.error(`Ocorreu um erro ao buscar os usuários...`);
		} finally {
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		getUsers();
	}, [getUsers]);

	return (
		<Container>
			<Title>Melhores vendedores</Title>
			<Table
				columns={columns}
				rows={users.data}
				loading={loading}
				preLoadRows={10}
			/>
		</Container>
	);
};

export default TableRanking;
