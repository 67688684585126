import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useField } from '@unform/core';
import { FiUploadCloud } from 'react-icons/fi';

import {
  Container,
  Field,
  Label,
  Error,
  Preview,
  BoxIconUpload,
} from './styles';

const Image = () => {
  const inputRef = useRef(null);

  // defaultValue
  const { fieldName, registerField, defaultValue = '', error } = useField(
    'shield'
  );
  const [preview, setPreview] = useState(defaultValue || 'no-image');

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'files',
    });
  }, [fieldName, registerField]);

  const handleChange = useCallback((e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];

      setPreview(URL.createObjectURL(file));
    }
  }, []);

  return (
    <Container className="container-input">
      <Label htmlFor={fieldName}>
        <Preview src={preview} />
        <Field
          ref={inputRef}
          type="file"
          id={fieldName}
          name={fieldName}
          onChange={handleChange}
          accept="image/png, image/jpeg, image/jpg"
        />
        Imagem
        <BoxIconUpload>
          <FiUploadCloud />
        </BoxIconUpload>
      </Label>
      {error && <Error>{error}</Error>}
    </Container>
  );
};

export default Image;
