import yup from '~/libs/yup';

export const createSchema = yup.object().shape({
  region: yup.string().required().label('Região'),
  name: yup.string().required().label('Nome'),
  start: yup.date().required().label('Início'),
  has_tie: yup.boolean().required().label('Opções'),
  max_games: yup
    .number()
    .transform((value, originalValue) =>
      originalValue.trim() === '' ? -1 : value
    )
    .integer()
    .min(1)
    .max(10000)
    .required()
    .label('Quantidade de jogos'),
  max_bets: yup
    .number()
    .transform((value, originalValue) =>
      originalValue.trim() === '' ? -1 : value
    )
    .integer()
    .min(1)
    .max(100000)
    .required()
    .label('Máximo de apostas'),
  value_bet: yup
    .number()
    .transform((value, originalValue) =>
      originalValue.trim() === '' ? -1 : value
    )
    .integer()
    .min(1, 'deve ser no mínimo 0,01')
    .required()
    .label('Valor da aposta'),
  min_award: yup
    .number()
    .transform((value, originalValue) =>
      originalValue.trim() === '' ? -1 : value
    )
    .integer().
    label('Prêmio mínimo'),
  award: yup
    .number()
    .transform((value, originalValue) =>
      originalValue.trim() === '' ? -1 : value
    )
    .min(0)
    .max(100)
    .required()
    .label('Prêmio'),
  commission_admin: yup
    .number()
    .transform((value, originalValue) =>
      originalValue.trim() === '' ? -1 : value
    )
    .min(0)
    .max(100)
    .required()
    .label('Comissão do administrador'),
  commission_manager: yup
    .number()
    .transform((value, originalValue) =>
      originalValue.trim() === '' ? -1 : value
    )
    .min(0)
    .max(100)
    .required()
    .label('Comissão do gerente'),
  commission_sub_manager: yup
    .number()
    .transform((value, originalValue) =>
      originalValue.trim() === '' ? -1 : value
    )
    .min(0)
    .max(100)
    .required()
    .label('Comissão do subgerente'),
  commission_seller: yup
    .number()
    .transform((value, originalValue) =>
      originalValue.trim() === '' ? -1 : value
    )
    .min(0)
    .max(100)
    .required()
    .label('Comissão do vendedor'),
  managers: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string().required(),
      })
    )
    .min(1)
    .max(6759)
    .required()
    .label('Gerentes'),
});
