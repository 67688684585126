import React from 'react';

import { Container, Label, Value } from './styles';

const CardCountTeam = ({ value }) => {
	return (
		<Container>
			<Label>Times</Label>
			<Value>{value}</Value>
		</Container>
	);
};

export default CardCountTeam;
