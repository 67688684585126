import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;
  overflow: hidden;

  border-radius: 8px;

  border: 1px solid #151a5d;
  background-color: #222e3b;

  ${({ availableToSetResult }) =>
    availableToSetResult &&
    css`
      border-color: #90ca28;
    `}
`;

export const Hit = styled.div`
  height: 20px;
  width: 20px;

  border-radius: 50%;

  margin-left: 10px;

  ${({ hit }) =>
    hit
      ? css`
          background-color: #90ca28;
        `
      : css`
          background-color: #ff3b30;
        `}
`;

export const Result = styled.div`
  color: #ffffff;
`;

export const Header = styled.div`
  position: relative;

  display: flex;
  justify-content: space-between;

  padding: 15px;
  background-color: #192129;
`;

export const Championship = styled.div`
  font-size: 18px;
  color: #ffffff;
`;

export const TeamsContainer = styled.div`
  display: grid;
  grid-template-columns: 10fr 1fr 10fr;

  justify-content: center;

  padding: 30px;

  button {
    margin-top: 10px;
    padding: 5px 7px;
  }
`;

export const TeamProfile = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  color: #ffffff;

  &:after {
    display: none;
    content: 'Vencedor';
    position: absolute;
    bottom: 0;
    left: 50%;

    padding: 5px;
    border-radius: 4px;

    transform: translate(-50%, +100%);

    font-size: 14px;
    color: #90ca28;
  }
`;

export const TeamHostProfile = styled(TeamProfile)`
  ${({ result }) => {
    if (result === 'guest' || result === 'tie')
      return css`
        .root-team-avatar {
          filter: grayscale(100%);
        }
      `;

    if (result === 'host') {
      return css`
        color: #90ca28;

        &:after {
          display: block;
        }
      `;
    }

    return css``;
  }}
`;

export const TeamGuestProfile = styled(TeamProfile)`
  ${({ result }) => {
    if (result === 'host' || result === 'tie')
      return css`
        .root-team-avatar {
          filter: grayscale(100%);
        }
      `;

    if (result === 'guest') {
      return css`
        color: #90ca28;

        &:after {
          display: block;
        }
      `;
    }

    return css``;
  }}
`;

export const TeamName = styled.div`
  width: 100%;
  font-size: 16px;

  margin-top: 5px;
  text-align: center;
`;

export const VsContainer = styled.div`
  margin: 0 15px;

  align-self: center;

  color: #90ca28;

  text-align: center;
`;

export const VsText = styled.div`
  font-weight: 900;
  font-size: 32px;
`;

export const VsResult = styled.div`
  font-weight: 500;
  font-size: 20px;
`;

export const GameDate = styled.div`
  color: #ffffff;
  font-size: 16px;

  text-align: center;

  padding-bottom: 30px;
`;
