import styled from 'styled-components';

import devices from '~/styles/configs/devices';

export const Container = styled.tbody`
  color: #fff;

  tr {
    background-color: #222e3b;

    @media ${devices.laptop} {
      td {
        border-top: 1px solid #324539;
        border-bottom: 1px solid #324539;
        border-right: none;
        border-left: none;

        &:first-child {
          border-left: 1px solid #324539;
          border-radius: 4px 0 0 4px;
        }

        &:last-child {
          border-right: 1px solid #324539;

          border-radius: 0 4px 4px 0;
        }
      }
    }
  }
`;
