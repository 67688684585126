import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useEffect,
  useState,
  useRef,
} from 'react';

import { toast } from 'react-toastify';

import Pagination from '~/components/Pagination';
import TeamTable from '~/components/Table';
import TableActions from '~/components/TableActions';
import useIsMountedRef from '~/hooks/useIsMountedRef';
import useModalDelete from '~/hooks/useModalDelete';
import api from '~/services/api';

import ModalForm from '../ModalForm';
import { Container, Profile, Image } from './styles';

const INITIAL_TEAMS = {
  data: [],
  page: 0,
  limit: 0,
  totalData: 0,
  totalPages: 0,
};

const Table = (props, ref) => {
  const modalFormRef = useRef(null);

  const isMountedRef = useIsMountedRef();

  const { openModalDelete } = useModalDelete();

  const [teams, setTeams] = useState(INITIAL_TEAMS);

  const [loading, setLoading] = useState(true);

  const getTeams = useCallback(async (page = 1) => {
    try {
      const { data } = await api.get(`/team?page=${page}&perPage=10`);

      setTeams(data);
    } catch (error) {
      toast.error('Ocorreu um erro ao buscar os times...');
      setTeams(INITIAL_TEAMS);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (isMountedRef) getTeams();
  }, [isMountedRef, getTeams]);

  useImperativeHandle(ref, () => ({
    getTeams,
  }));

  const handleClickDelete = async (event) => {
    try {
      event.persist();

      const { value } = event.target;

      openModalDelete({
        url: `/team/${value}`,
        title: 'Deseja deletar este time?',
        onDelete: () => getTeams(1),
        autoClose: true,
      });
    } catch (error) {
      console.log('>>> error', error);
    }
  };

  const handleClickEdit = (event) => {
    const { value } = event.target;

    const team = teams.data.find(({ _id }) => _id === value);
    modalFormRef.current.openModal(team);
  };

  const columns = [
    {
      key: ['shield', 'name', 'initials'],
      label: 'Time',
      // eslint-disable-next-line react/prop-types
      format: ({ shield, name, initials }) => (
        <Profile>
          <Image src={shield} />
          {initials} - {name}
        </Profile>
      ),
    },
    {
      key: 'address',
      label: 'País',
      format: ({country, state}) => (
        <>
          { state && `${state} - ` }
          {country}
        </>
      ),
    },
    {
      key: '_id',
      label: 'Ações',
      width: 100,
      format: (_id) => (
        <TableActions
          value={_id}
          onEdit={handleClickEdit}
          onDelete={handleClickDelete}
        />
      ),
    },
  ];

  return (
    <Container>
      <ModalForm ref={modalFormRef} onSave={getTeams} />
      <TeamTable
        columns={columns}
        rows={teams.data}
        loading={loading}
        preLoadRows={10}
      />
      <Pagination
        onChange={getTeams}
        page={teams.page}
        pageSize={teams.limit}
        total={teams.totalData}
        totalPages={teams.totalPages}
        position="flex-end"
      />
    </Container>
  );
};

export default forwardRef(Table);
