import React from 'react';

import PropTypes from 'prop-types';

import masks from '~/utils/masks';

import { Container, Title, Points, Text, Value } from './styles';

const CardResult = ({ title, type, result, data }) => {
  
  const getAward = () => {
    let award = false;
    
    if(result.points && data.bet.points == result.points.max) {
      award = 'max'
    }else if(result.points && data.bet.points == result.points.min) {
      award = 'min'
    }

    return award
  }

  const calcAward = () => {
    let r = getAward();

    if(r) return result.award[r]

    return result.accumulated || 0
  }

  const getAwardText = () => {
    let r = getAward();

    if(r) return `Você ganhou com ${(r == 'max'? 'mais':'menos')} pontos.`

    return `Boa sorte na próxima rodada.`
  }

  return (
    <Container>
      <Title>{title}</Title>
      { type == 'award' && <Value>{masks.currency(calcAward())}</Value> }
      { type == 'winners' && (
        result.points ? [
          <Text>{ result.points?.max } pontos: { result.bet?.max } ganhadores.</Text>,
          <Text>{ result.points?.min } pontos: { result.bet?.min } ganhadores.</Text>
        ]:[
          <Text>&nbsp;</Text>,
          <Text>Aguarde o encerramento da rodada.</Text>,
        ]
      ) }
      { type == 'points' && [
        <Points>{ data.bet?.points } pontos</Points>,
        <Text>{ getAwardText() }</Text>
      ] }
      
    </Container>
  );
};

CardResult.defaultProps = {
  title: '',
  type: '',
  data: {},
  result: {}
};

CardResult.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  data: PropTypes.any,
  result: PropTypes.any,
};

export default CardResult;
