import React from 'react';

import isAfter from 'date-fns/isAfter';
import parseJSON from 'date-fns/parseJSON';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import Private from '~/pages/_layouts/Private';
import { Creators as AuthActions } from '~/store/ducks/auth';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state);

  const isAuthenticated = () => {
    const isValid =
      auth.token && isAfter(parseJSON(auth.expiresIn), new Date());

    if (!isValid) {
      dispatch(AuthActions.logout());
    }

    return isValid;
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          <Private>
            <Component {...props} />
          </Private>
        ) : (
          <Redirect to="/admin/login" />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default PrivateRoute;
