import React, { useCallback, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Pagination from '~/components/Pagination';
import Table from '~/components/Table';
import TableActions from '~/components/TableActions';
import useIsMountedRef from '~/hooks/useIsMountedRef';
import useModalDelete from '~/hooks/useModalDelete';
import ControlsTabs from '~/pages/Round/View/ControlsTabs';
import api from '~/services/api';
import masks from '~/utils/masks';

import jsPDF from 'jspdf';

import { Container, Content, Head, Title } from './styles';

const INITIAL_BETS = {
	data: [],
	page: 0,
	limit: 0,
	totalData: 0,
	totalPages: 0,
};

const ManagerGroups = () => {
	const isMountedRef = useIsMountedRef();

	const { id } = useParams();

	const { openModalDelete } = useModalDelete();

	const [groups, setGroups] = useState(INITIAL_BETS);
	const [round, setRound] = useState({
		name: '',
		games: [],
	});
	const [loading, setLoading] = useState(true);

	const getsGroupsByRound = useCallback(
		async (page = 1) => {
			if (!id) return;

			try {
				setLoading(true);
				const { data } = await api.get(
					`/round-manager?page=${page}&perPage=10&round_id=${id}`
				);

				setGroups(data);
			} catch (error) {
				setLoading(false);
				toast.error('Ocorreu um erro ao buscar os grupos...');
				setGroups(INITIAL_BETS);
			} finally {
				setLoading(false);
			}
		},
		[id]
	);

	const getRound = useCallback(async () => {
		try {
			const { data } = await api.get(`/round/${id}`);

			setRound(data);
		} catch (error) {
			toast.error('Ocorreu um erro ao buscar a rodada...');
			setRound([]);
		} finally {
		}
	}, [id]);

	useEffect(() => {
		if (isMountedRef) {
			getRound();
			getsGroupsByRound();
		}
	}, [isMountedRef, getsGroupsByRound, getRound]);

	const columns = [
		{
			key: 'group.name',
			label: 'Equipe',
		},
		{
			key: 'manager.name',
			label: 'Gerente',
		},
		{
			key: 'group',
			label: '# Sub gerentes',
			format: (group) => <div>{group?.sub_managers?.length}</div>,
		},
		{
			key: 'bets',
			label: '# Apostas',
		},

		{
		   key: ['round_manager', 'group', 'manager'],
		   label: 'Relatório',
		   width: 70,
		   format: ({ round_manager, group, manager }) => (
		     <TableActions value={round_manager} onView={() => { handleReport({round_manager, group, manager}) }} /> 
		   ),
		}
	];

	const handleReport = async ({round_manager, group, manager}) => {
		try {
			setLoading(true);

			const { data } = await api.get('/statistics/awards', {
				params: {
					round_id: id, 
					round_manager: round_manager
				},
			});
			var doc = new jsPDF();

			doc.setFontSize(30);
			doc.text(round.name, 100, 25, 'center');

			doc.setFontSize(20);
			doc.text(`Prêmio ${masks.currency(data.award.total)}`, 100, 35, 'center');

			doc.setFontSize(18);
			doc.text(`Vencedores com mais pontos - ${data.points.max} pontos`, 15, 50);
			
			doc.setFontSize(14);
			doc.text(`Rateio - ${masks.currency(data.award.total / 2)}`, 15, 57);

			let y = 67
			data.bets.forEach(bet => {
				if(bet.points == data.points.max) {
					doc.text(`${bet.code} - ${bet.name} (${bet.phone}) ${masks.currency(data.award.max)}`, 15, y);
					y+= 7
				}
			});

			y+= 15
			doc.setFontSize(18);
			doc.text(`Vencedores com menos pontos - ${data.points.min} pontos`, 15, y);
			
			y+= 7
			doc.setFontSize(14);
			doc.text(`Rateio - ${masks.currency(data.award.total / 2)}`, 15, y);

			y+= 10
			data.bets.forEach(bet => {
				if(bet.points == data.points.min) {
					doc.text(`${bet.code} - ${bet.name} (${bet.phone}) ${masks.currency(data.award.min)}`, 15, y);
					y+= 7
				}
			});

			doc.save(`Premiação ${group.name} (${manager.email}).pdf`);
		} catch (err) {
			console.log(err);
			setLoading(false);
		}
	};

	return (
		<Container>
			<Head>
				<Title>Rodadas - {round.name}</Title>
			</Head>
			<ControlsTabs
				round={round}
				availableForBet={round.availableForBet}
				getRound={getRound}
				active="Grupos"
				roundId={id}
			/>
			<Content>
				<Table
					columns={columns}
					rows={groups}
					loading={loading}
					preLoadRows={10}
				/>
				{/* <Pagination
					onChange={getsGroupsByRound}
					page={groups.page}
					pageSize={groups.limit}
					total={groups.totalData}
					totalPages={groups.totalPages}
					position="flex-end"
				/> */}
			</Content>
		</Container>
	);
};

export default ManagerGroups;
