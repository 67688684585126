import React, { useRef } from 'react';

import FadeIn from 'react-fade-in';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ReactComponent as Logo } from '~/assets/svg/logo.svg';
import Button from '~/components/Button';
import Form from '~/components/Form';
import Input from '~/components/Input';
import api from '~/services/api';
import { Creators as AuthActions } from '~/store/ducks/auth';
import yupValidate from '~/utils/yupValidate';
import loginSchema from '~/validators/login.schema';

import { Container, Content } from './styles';

const Login = () => {
  const dispatch = useDispatch();

  const formRef = useRef(null);

  const history = useHistory();

  const handleSubmit = async (formData) => {
    try {
      formRef.current.setErrors({});
      const { success, data, errors } = await yupValidate(
        loginSchema,
        formData
      );

      if (!success) {
        return formRef.current.setErrors(errors);
      }

      const {
        data: { token, expiresIn, user },
      } = await api.post('/me', data);

      dispatch(AuthActions.login(token, expiresIn, user));
      return history.push('/admin');
    } catch (error) {
      const { status } = error.response;

      if (status === 401) {
        return toast.error(
          'Suas credencias estão inválidas ou sua conta foi inativada...'
        );
      }

      return toast.error('Ocorreu um erro ao entrar na plataforma...');
    }
  };

  return (
    <Container>
      <Content>
        <FadeIn>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Logo />
            <Input
              name="email"
              placeholder="E-mail"
              variant="border"
              fullWidth
            />
            <Input
              type="password"
              name="password"
              placeholder="Senha"
              variant="border"
              fullWidth
            />
            <Button type="submit" label="Login" fullWidth />
          </Form>
        </FadeIn>
      </Content>
    </Container>
  );
};

export default Login;
