import React from 'react';

import { BrowserRouter, Switch } from 'react-router-dom';

import Bet from '~/pages/Bet';
import Dashboard from '~/pages/Dashboard';
import Login from '~/pages/Login';
import ManagerGroups from '~/pages/ManagerGroups';
import NotFound from '~/pages/NotFound';
import NotFoundAdmin from '~/pages/NotFoundAdmin';
import Profile from '~/pages/Profile';
import Round from '~/pages/Round';
import Team from '~/pages/Team';
import User from '~/pages/User';

import LoginRoute from './LoginRoute';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

const Routes = () => {
	return (
		<BrowserRouter>
			<Switch>
				<LoginRoute path="/admin/login" component={Login} />
				<PrivateRoute path="/admin/" component={Dashboard} exact />
				{/* <PrivateRoute path="/admin/apostas" component={Bet.List} /> */}
				{/* <PrivateRoute path="/gerenciamento" component={Management} /> */}
				<PrivateRoute path="/admin/times" component={Team} />
				<PrivateRoute path="/admin/rodadas" component={Round.List} exact />
				<PrivateRoute path="/admin/rodadas/:id/" component={Round.View} exact />
				<PrivateRoute path="/admin/rodadas/:id/apostas" component={Bet.List} />
				<PrivateRoute
					path="/admin/rodadas/:id/grupos"
					component={ManagerGroups}
				/>

				<PrivateRoute path="/admin/usuarios" component={User} />
				<PrivateRoute path="/admin/perfil" component={Profile} />
				<PublicRoute path="/admin/*" component={NotFoundAdmin} />
				<PublicRoute path="/" component={Bet.Consult} exact />
				<PublicRoute path="/:round/:seller" component={Bet.Result} />
				<PublicRoute path="/:code" component={Bet.Result} />
				<PublicRoute path="*" component={NotFound} />
			</Switch>
		</BrowserRouter>
	);
};

export default Routes;
