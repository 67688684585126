import React from 'react';

import PropTypes from 'prop-types';

import { Container } from './styles';

const Td = ({ children, align, ...rest }) => {
  return (
    <Container align={align} {...rest}>
      {children}
    </Container>
  );
};

Td.defaultProps = {
  align: 'left',
};

Td.propTypes = {
  align: PropTypes.string,

  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

export default Td;
