import styled from 'styled-components';

import devices, { sizes } from '~/styles/configs/devices';

export const Container = styled.td`
  font-weight: 500;
  font-size: 14px;
  text-align: left;

  padding: 10px;

  @media ${`(max-width: ${sizes.tablet})`} {
    display: block;

    &:before {
      content: attr(data-label);

      float: left;

      font-weight: 800;
      color: #90ca28;

      margin-right: 10px;
    }
  }
`;
