import React from 'react';

import { MdSettings } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

import Logout from './Logout';
import { Container, Content, Button } from './styles';

const Actions = () => {
  const history = useHistory();

  const handleClick = () => {
    history.push('/admin/perfil');
  };

  return (
    <Container>
      <Content>
        <Button type="button" onClick={handleClick}>
          <MdSettings />
        </Button>
      </Content>
      <Logout />
    </Container>
  );
};

export default Actions;
