import React, { useEffect, useState } from 'react';

import Select from '~/components/Select';
import api from '~/services/api';

const SelectTeams = () => {
  const [teams, setTeams] = useState();

  useEffect(() => {
    const getTeams = async () => {
      try {
        const {
          data: { data, totalData },
        } = await api.get(`/team`);

        if (totalData > 0)
          return setTeams(
            data.map(({ _id, name, address }) => ({
              label: `${name} ${address.country !== 'Brasil' ? ' - ' + address.country:''}`,
              value: _id,
            }))
          );

        return setTeams([]);
      } catch (error) {
        console.log('>>> error', error);

        return setTeams([]);
      }
    };

    getTeams();
  }, []);

  return (
    <>
      <Select
        label="Time mandante"
        name="team_host_id"
        options={teams}
        isSearchable
      />
      <Select
        label="Time visitante"
        name="team_guest_id"
        options={teams}
        isSearchable
      />
    </>
  );
};

export default SelectTeams;
