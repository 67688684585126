import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useRef,
} from 'react';

import { toast } from 'react-toastify';

import Input from '~/components/Input';
import Modal from '~/components/Modal';
import useModal from '~/hooks/useModal';
import api from '~/services/api';
import masks from '~/utils/masks';
import yupValidate from '~/utils/yupValidate';
import { createSchema, updateSchema } from '~/validators/user.schema';

const ModalForm = ({ ...rest }, ref) => {
  const formRef = useRef(null);

  const { isOpen, closeModal, openModal } = useModal();

  const [initialData, setInitialData] = useState({});

  useImperativeHandle(ref, () => ({
    openModal: (data = {}) => {
      setInitialData(data);
      openModal();
    },
  }));

  const createUser = async (data) => {
    const unmaskedPhone = masks.unmask(data.phone);

    const response = await api.post('/user', {
      ...data,
      phone: unmaskedPhone,
      role: 'manager',
    });

    return response;
  };

  const updateUser = async (data) => {
    console.log(data);
    const response = await api.put('/user', data);

    return response;
  };

  const handleSubmit = async (formData) => {
    try {
      formRef.current.setErrors({});

      const validationSchema = initialData._id ? updateSchema : createSchema;

      if (initialData._id && formData.shield.length === 0)
        delete formData.shield;

      const { success, data, errors } = await yupValidate(
        validationSchema,
        formData
      );

      if (!success) {
        return formRef.current.setErrors(errors);
      }

      // Dados básicos
      const {
        data: { _id },
      } = initialData._id
        ? await updateUser({ id: initialData._id, ...data })
        : await createUser(data);

      closeModal();

      if (rest.onSave) rest.onSave();

      toast.success('Sucesso ao cadastrar o gerente!');

      return _id;
    } catch (error) {
      if (error.response) {
        const { status, data } = error.response;

        if (status === 400) {
          return toast.error(
            `Ocorreram erros na validação... Erros: ${data.error.join('')}`
          );
        }
      }

      return toast.error(
        `Ocorreu um erro ao tentar ${
          initialData._id ? 'cadastrar' : 'editar'
        } o gerente...`
      );
    }
  };

  return (
    <Modal
      formRef={formRef}
      title="Novo gerente"
      isOpen={isOpen}
      closeModal={closeModal}
      onSubmit={handleSubmit}
      initialData={initialData}
    >
      <Input label="Nome" name="name" variant="border" />
      <Input label="E-mail" name="email" variant="border" />
      <Input label="Celular" name="phone" mask="cellphone" variant="border" />
    </Modal>
  );
};

export default forwardRef(ModalForm);
