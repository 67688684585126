import React, { useCallback, useEffect, useRef, useState } from 'react';

import { toast } from 'react-toastify';

import Button from '~/components/Button';
import Pagination from '~/components/Pagination';
import Table from '~/components/Table';
import TableActions from '~/components/TableActions';
import useIsMountedRef from '~/hooks/useIsMountedRef';
import useModalDelete from '~/hooks/useModalDelete';
import api from '~/services/api';
import masks from '~/utils/masks';

import ModalForm from './ModalForm';
import { Container, Content, Head, Title } from './styles';

const INITIAL_USERS = {
	data: [],
	page: 0,
	limit: 0,
	totalData: 0,
	totalPages: 0,
};

const User = () => {
	const modalFormRef = useRef(null);

	const isMountedRef = useIsMountedRef();

	const { openModalDelete } = useModalDelete();

	const [users, setUsers] = useState(INITIAL_USERS);

	const [loading, setLoading] = useState(true);

	const getUsers = useCallback(async (page = 1) => {
		try {
			const { data } = await api.get(`/user?page=${page}&perPage=10`);

			setUsers(data);
		} catch (error) {
			setUsers(INITIAL_USERS);

			toast.error(`Ocorreu um erro ao buscar os usuários...`);
		} finally {
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		if (isMountedRef) getUsers();
	}, [isMountedRef, getUsers]);

	const handleClickOpenModal = () => modalFormRef.current.openModal();

	const handleChangePage = (page) => getUsers(page);

	const handleClickDelete = async (event) => {
		try {
			event.persist();

			const { value } = event.target;

			openModalDelete({
				url: `/user/${value}`,
				title: 'Deseja deletar o usuário?',
				onDelete: () => getUsers(1),
				autoClose: true,
			});
		} catch (error) {
			console.log('>>> error', error);
		}
	};

	const roles = {
		admin: 'Administrador',
		manager: 'Gerente',
		sub_manager: 'Subgerente',
		seller: 'Vendedor',
	};

	const columns = [
		{
			key: 'name',
			label: 'Nome',
		},
		{
			key: 'phone',
			label: 'Telefone',
			format: (value) => masks.cellphone(value),
		},
		{
			key: 'role',
			label: 'Tipo',
			format: (value) => roles[value],
		},
		{
			key: 'superiority.name',
			label: 'Responsável',
		},
		{
			key: '_id',
			label: 'Ações',
			width: 70,
			format: (_id) => (
				<TableActions value={_id} onDelete={handleClickDelete} />
			),
		},
	];

	return (
		<Container>
			<Head>
				<Title>Usuários</Title>
				<Button label="Novo usuário" onClick={handleClickOpenModal} />
				<ModalForm ref={modalFormRef} onSave={getUsers} />
			</Head>
			<Content>
				<Table
					columns={columns}
					rows={users.data}
					loading={loading}
					preLoadRows={10}
				/>
				<Pagination
					onChange={handleChangePage}
					page={users.page}
					pageSize={users.limit}
					total={users.totalData}
					totalPages={users.totalPages}
					position="flex-end"
				/>
			</Content>
		</Container>
	);
};

export default User;
