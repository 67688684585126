import styled, { css } from 'styled-components';

import fadeIn from '~/styles/animations/fadeIn';

export const Container = styled.div`
  ${fadeIn};

  width: 100%;

  ${({ fullWidth }) => {
    if (fullWidth)
      return css`
        max-width: none;
      `;

    return css`
      max-width: 300px;
    `;
  }}
`;

export const Field = styled.input`
  -webkit-appearance: none;

  width: 100%;
  font-size: 16px;

  padding: 10px;

  border-radius: 3px;
  transition: border-color 200ms linear;

  &::placeholder {
    font-size: 16px;
    color: #aeaeb2;
  }

  ${({ error }) =>
    error &&
    css`
      border-color: #ff3b2f;

      &:hover {
        border-color: #f83019;
      }
    `}

  ${({ variant }) => {
    if (variant === 'border')
      return css`
        border: 1px solid #e0e0e0;

        :hover,
        :focus {
          border-color: #aeaeb2;
        }
      `;

    return '';
  }}
`;

export const Label = styled.label`
  display: inline-block;
  width: 100%;
  font-size: 16px;
  margin-bottom: 5px;

  ${({ color }) => {
    if (color === 'black')
      return css`
        color: '#000000';
      `;

    return css`
      color: ${color};
    `;
  }}

  ${({ fontStyle }) => {
    if (fontStyle === 'bold')
      return css`
        font-weight: 500;
      `;
    
    return css `
      font-size: 14px;
    `
  }}
`;

export const Error = styled.span`
  color: #ff3b2f;
  font-size: 12px;
`;
