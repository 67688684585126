import styled from 'styled-components';

export const Container = styled.div`
  border: 1px solid #354640;
  background-color: #242f44;
  border-radius: 8px;
  padding: 15px;

  width: 300px;

  @media (max-width: 992px) {
    margin-bottom: 10px;
    width: 100%
  }
`;

export const Title = styled.div`
  color: #ffffff;
  font-size: 12px;
  margin-bottom: 10px;
`;

export const Text = styled.div`
  color: #ffffff;
  font-size: 14px;
  margin-bottom: 4px;
`;

export const Value = styled.div`
  color: #d9bc3e;
  font-size: 34px;
  font-weight: 500;
`;

export const Points = styled.div`
  color: #d9bc3e;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
`;