import styled from 'styled-components';
// import devices from '~/styles/configs/devices';

export const Container = styled.div`
  min-height: 100%;
  background-color: #12154a;
`;

export const Content = styled.div`
  padding-top: 80px;

  width: 100%;
  max-width: 1440px;

  margin: 0 auto;
`;
