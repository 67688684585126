import React from 'react';

import { Form } from '@unform/web';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import { IoIosClose } from 'react-icons/io';

import Button from '~/components/Button';

import { Container, Content, ButtonClose, Title, Controls } from './styles';

const Modal = ({
	formRef,
	title,
	onSubmit,
	children,
	isOpen,
	closeModal,
	initialData,
	maxWidth,
	hasBackground
}) => {
	if (isOpen)
		return createPortal(
			<Container>
				<Content maxWidth={maxWidth} hasBackground={hasBackground}>
					<Form ref={formRef} onSubmit={onSubmit} initialData={initialData}>
						<ButtonClose type="button" onClick={closeModal}>
							<IoIosClose />
						</ButtonClose>
						<Title>{title}</Title>
						{children}

						{onSubmit && (
							<Controls>
								<Button type="submit" label="Salvar" />
							</Controls>
						)}
					</Form>
				</Content>
			</Container>,
			document.body
		);

	return null;
};

Modal.defaultProps = {
	initialData: {},
	maxWidth: 500,
};

Modal.propTypes = {
	initialData: PropTypes.oneOfType([PropTypes.object]),
	title: PropTypes.string.isRequired,
	isOpen: PropTypes.bool.isRequired,
	maxWidth: PropTypes.number,
	onSubmit: PropTypes.func,
	children: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.object,
		PropTypes.array,
	]).isRequired,
};

export default Modal;
