import React from 'react';

import PropTypes from 'prop-types';
import { MdEdit, MdDelete, MdRemoveRedEye } from 'react-icons/md';

import { Container, DeleteButton, EditButton, ViewButton } from './styles';

const TableActions = ({ value, onDelete, onEdit, onView }) => {
  return (
    <Container>
      {onView && (
        <ViewButton type="button" value={value} onClick={onView}>
          <MdRemoveRedEye />
        </ViewButton>
      )}
      {onEdit && (
        <EditButton type="button" value={value} onClick={onEdit}>
          <MdEdit />
        </EditButton>
      )}
      {onDelete && (
        <DeleteButton type="button" value={value} onClick={onDelete}>
          <MdDelete />
        </DeleteButton>
      )}
    </Container>
  );
};

TableActions.defaultProps = {
  value: '',
  onDelete: '',
  onEdit: '',
  onView: '',
};

TableActions.propTypes = {
  value: PropTypes.string,
  onDelete: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  onEdit: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  onView: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};

export default TableActions;
