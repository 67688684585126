import React, { useCallback } from 'react';

import Select from '~/components/Select';
import api from '~/services/api';

const SelectManagers = () => {
  // const [timer, setTimer] = useState();

  const handleLoadOptions = useCallback(async (value = '') => {
    try {
      const {
        data: { data, totalData },
      } = await api.get(`/group?filter=${value}&perPage=10000`);

      if (totalData > 0)
        return data.map(({ _id, name }) => ({
          label: name,
          value: _id,
        }));

      return [];
    } catch (error) {
      console.log('>>> error', error);

      return [];
    }
  }, []);

  return (
    <>
      <Select
        label="Grupos"
        name="managers"
        defaultOptions
        cacheOptions
        onFocus={() => handleLoadOptions()}
        loadOptions={handleLoadOptions}
        isMulti
        isSearchable
        async
      />
    </>
  );
};

export default SelectManagers;
