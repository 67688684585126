import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import api from '~/services/api';

import CardCountBet from './CardCountBet';
import CardCountMatch from './CardCountMatch';
import CardCountSeller from './CardCountSeller';
import CardCountTeam from './CardCountTeam';
import CardLastGame from './CardLastGame';
import { Container, Head, Title } from './styles';
import TableLast from './TableLast';
import TableRanking from './TableRanking';

const Dashboard = () => {

	const [statistics, setStatistics] = useState({});

	const [loading, setLoading] = useState(true);

	const getStatistics = useCallback(async () => {
		try {
			const { data } = await api.get(`/statistics`);

			setStatistics(data);
		} catch (error) {
			setStatistics({});

			//toast.error(`Ocorreu um erro ao buscar os usuários...`);
		} finally {
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		getStatistics();
	}, []);

	return (
		<Container>
			<Head>
				<Title>Dashboard</Title>
			</Head>
			<CardLastGame />
			<CardCountBet value={statistics.bets} />
			<CardCountTeam value={statistics.teams} />
			<CardCountMatch value={statistics.rounds_active} />
			<CardCountSeller value={statistics.users} />
			<TableLast />
			<TableRanking />
		</Container>
	);
};

export default Dashboard;
