import React, { useEffect, useState, useRef } from 'react';

import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import api from '~/services/api';

import masks from '~/utils/masks';
import Button from '~/components/Button';

import Award from './Award';
import CardGameBet from '../../Round/View/CardGame';
import CardGame from './CardGame';
import CardResult from './CardResult';
import BetForm from './BetForm';

import {
  Seller,
  Price,
  Container,
  Content,
  Info,
  Head,
  Title,
  HitContainer,
  HitLegend,
  Legend,
  Hit,
  Pending,
  ListGames,
} from './styles';

const Result = () => {
  const { state } = useLocation();
  const { code, round, seller } = useParams();
  
  const modalFormRef = useRef(null);

  const handleClickOpenModal = () => modalFormRef.current.openModal({results, round_id: data._id, seller });

  const [round_result, setResult] = useState(false)
  const [isReady, setIsReady] = useState(false)
  const [results, setResults] = useState({})

  const [data, setData] = useState({
    award: 0,
    bet: {},
    round: {},
    games: [],

  });
  
  useEffect(() => {
    const getAward = async (round_id, round_manager) => {
      try {

        const response = await api.get(`/statistics/awards`, {
          params: { round_id, round_manager }
        });

        setResult(response.data)

      } catch (error) {
        toast.error('Ocorreu um erro ao buscar a sua premiação.');
      }
    }

    const getBetByCode = async () => {
      try {
        const { data: res } = await api.get(`/bet/code/${code}`);

        setData(res);

        getAward(res.bet.round._id, res.bet.round_manager)
      } catch (error) {
        const { status } = error.response || error;

        if (status) {
          toast.error('Verifique o código e tente novamente...');
        } else {
          toast.error(
            'Ocorreu um erro ao entrar ao buscar a aposta, verifique o código e tente novamente...'
          );
        }
      }
    };

    const getRoundByCode = async () => {
      try {
        const { data } = await api.get(`/round/${round}/${seller}`);

        setData(data)

      }catch(error) {
        toast.error('Esta rodada já foi finalizada');
      }
    }

    if(code) getBetByCode();

    if(round && seller) getRoundByCode();

    if (state) {
      setData(state.data);

      getAward(state.data.bet.round._id, state.data.bet.round_manager)
    }
  }, [code, state, round, seller]);

  const handleSelectResult = async(id, result) => {
    const _results = results
    delete _results[id]

    if(_results != '') _results[id] = result
    
    setResults(_results)
    setIsReady(Object.keys(results).length == data.games.length)
  }

  return (
    <Container>
      <Content>
        <Head>
          { data.bet ? [
            <Title>{data.bet?.code}</Title>,
            <div>Rodada {data.bet?.round?.name} - {data.bet?.round?.region}</div>
          ]:[
            <Title>{data.name} - {data.region}</Title>
          ]}
          
          { data.seller ? [
            <Seller>{data.seller?.name}</Seller>,
            <div>Chave PIX: {(data.seller?.pix_type || '').toUpperCase()} {data.seller?.pix_key}</div>,
            <Price>R$ {masks.currency(data?.value_bet || '')}</Price>
          ]:[
            <Seller>{data.bet?.document}</Seller>,
            data.bet?.status == 'P' && <Pending>Aguardando confirmação</Pending>
          ] }
        </Head>
        { round_result && [
          <Info>
            <CardResult type="points" title="Resultado" result={round_result} data={data}  />,
            <CardResult type="winners" title="Ganhadores" result={round_result} data={data} />,
            <CardResult type="award" title={round_result.accumulated ? 'Prêmio acumulado':'Seu prêmio'} result={round_result} data={data} />
          </Info>,
          <Legend>
            <HitContainer>
              <Hit hit />
              <HitLegend>Acertou o resultado</HitLegend>
            </HitContainer>
            <HitContainer>
              <Hit hit={false} />
              <HitLegend>Errou o resultado</HitLegend>
            </HitContainer>
          </Legend>
        ]}
        <ListGames>
          {data.games.map((game) => (
            <>
              { !data.seller ?
                <CardGame key={game._id} data={game} />:
                <CardGameBet key={game._id} data={game} setResult={true} hasTie={data.has_tie} onSetResult={handleSelectResult} showButton={false}/>
              }
            </>
          ))}
        </ListGames>
        { data.seller && 
          <center>
            <Button disabled={!isReady} label="Realizar Palpite" onClick={handleClickOpenModal} />
          </center>
        }
        <BetForm ref={modalFormRef} />
      </Content>
    </Container>
  );
};

export default Result;
