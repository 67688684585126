import styled from 'styled-components';

import { Container as Card } from '~/components/Card/styles';
import devices from '~/styles/configs/devices';

export const Container = styled(Card)`
	padding: 30px;

	@media ${devices.mobileS} {
		grid-column: 1fr;
	}

	@media ${devices.laptop} {
		grid-column: span 2;
	}
`;

export const Title = styled.div`
	color: #ffffff;
	font-weight: 500;
	font-size: 20px;
	line-height: 23px;
`;
