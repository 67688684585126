import styled from 'styled-components';
// import devices from '~/styles/configs/devices';

export const Container = styled.div`
  padding: 30px;
`;

export const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-bottom: 30px;
`;

export const Title = styled.div`
  font-size: 24px;
  color: #ffffff;
`;
