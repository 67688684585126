import React from 'react';

import { Form } from '@unform/web';
import { useSelector } from 'react-redux';

import Input from '~/components/Input';

import { Container, Head, Title, Content } from './styles';

const Profile = () => {
  const { user } = useSelector((state) => state.auth);

  return (
    <Container>
      <Head>
        <Title>Perfil</Title>
      </Head>
      <Content>
        <Form initialData={user}>
          <Input label="Nome" name="name" variant="border" />
          <Input label="E-mail" name="email" variant="border" />
          <Input label="Celular" name="phone" variant="border" mask="phone" />
        </Form>
      </Content>
    </Container>
  );
};

export default Profile;
