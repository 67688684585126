import React, { useEffect, useRef } from 'react';

import { useField } from '@unform/core';
import PropTypes from 'prop-types';
import Input from 'react-code-input';

import { Container, inputStyle, Error } from './styles';

const InputCode = ({ type, fields, name, ...rest }) => {
  const inputRef = useRef(null);

  const { fieldName, registerField, error } = useField(name);

  useEffect(() => {
    if (inputRef.current)
      registerField({
        name: fieldName,
        ref: inputRef.current,
        path: 'state.value',
      });
  }, [fieldName, registerField]);

  return (
    <Container>
      <Input
        ref={inputRef}
        type={type}
        name={fieldName}
        fields={fields}
        forceUppercase
        {...rest}
        {...inputStyle}
      />
      {error && <Error>{error}</Error>}
    </Container>
  );
};

InputCode.defaultProps = {
  type: 'text',
};

InputCode.propTypes = {
  type: PropTypes.string,
  fields: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
};

export default InputCode;
