import React from 'react';

import PropTypes from 'prop-types';

import masks from '~/utils/masks';

import { Container, Label, Value } from './styles';

const Award = ({ award }) => {
  return (
    <Container>
      <Label>Prêmio</Label>
      <Value>{masks.currency(award)}</Value>
    </Container>
  );
};

Award.defaultProps = {
  award: 0,
};

Award.propTypes = {
  award: PropTypes.number,
};

export default Award;
