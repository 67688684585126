import styled from 'styled-components';

import devices from '~/styles/configs/devices';

export const Container = styled.div`
  padding: 30px;

  display: grid;
  grid-gap: 30px;

  @media ${devices.mobileS} {
    grid-template-columns: 1fr;
  }

  @media ${devices.laptop} {
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  }
`;

export const Head = styled.div`
  grid-column: 1fr;

  @media ${devices.laptop} {
    grid-column: span 5;
  }
`;

export const Title = styled.div`
  font-size: 24px;
  color: #ffffff;
`;
