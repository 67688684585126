import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useRef,
} from 'react';

import { toast } from 'react-toastify';

import { useHistory } from 'react-router-dom';

import Input from '~/components/Input';
import Select from '~/components/Select';
import Modal from '~/components/Modal';
import useModal from '~/hooks/useModal';
import api from '~/services/api';
import yupValidate from '~/utils/yupValidate';
import { createBet } from '~/validators/bet.schema';

const ModalForm = ({ ...rest }, ref) => {
  const formRef = useRef(null);

  const history = useHistory();

  const { isOpen, closeModal, openModal } = useModal();

  const [initialData, setInitialData] = useState({});
  const [loading, setLoading] = useState(false);

  useImperativeHandle(ref, () => ({
    openModal: (data = {}) => {
      setInitialData(data);
      openModal();
    },
  }));

  const handleSubmit = async (formData) => {
    try {
      setLoading(true)
      formRef.current.setErrors({});

      const bets = []
      for(var i in initialData.results)
        bets.push({
          game_id: i,
          result: initialData.results[i]
        });

      const obj = {
        ...formData, bets,
        round_id: initialData.round_id
      }

      const { success, data, errors } = await yupValidate(createBet, obj);

      if (!success || loading) {
        setLoading(false)
        return formRef.current.setErrors(errors);
      }

      const { data: {
        code
      } } = await api.post(`/bet/${initialData.seller}`, data);
      
      setLoading(false)
      closeModal();

      toast.success('Palpite registrado com sucesso!');

      return history.push(`/${code}`)
    } catch (error) {
      return toast.error(`Ocorreu um erro ao tentar cadastrar a aposta`);
    }
  };

  return (
    <Modal
      formRef={formRef}
      title="Novo palpite"
      isOpen={isOpen}
      closeModal={closeModal}
      onSubmit={handleSubmit}
      initialData={initialData}
    >
      <Input label="Nome" name="name" variant="border" />
      <Input
          label="Telefone"
          mask="cellphone"
          name="phone"
          variant="border" />
      <Input
          label="CPF"
          name="document"
          mask="cpf"
          variant="border" />
      <Select
          label="Forma de pagamento"
          name="payment"
          defaultValue={true}
          options={[
            { label: 'PIX / Transferência', value: 'pix' },
            { label: 'Dinheiro', value: 'cash' }
          ]}
        />
    </Modal>
  );
};

export default forwardRef(ModalForm);