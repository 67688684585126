import styled, { css, keyframes } from 'styled-components';

import fadeIn from 'react-animations/lib/fade-in';

import ripple from '~/styles/animations/ripple';

const fadeInAnimation = keyframes`${fadeIn}`;

export const Container = styled.button`
  ${ripple};
  animation: 800ms ${fadeInAnimation};

  -webkit-appearance: none;

  text-align: center;
  font-weight: 500;

  border-radius: 3px;
  padding: 8px 12px;

  transition: background-color 200ms linear, box-shadow 200ms linear,
    border-color 200ms linear;

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}

  &:disabled {
    cursor: not-allowed;
    border: 1px solid #bdbdbd;
    background-color: #e0e0e0;
  }

  &.default {
    color: #707070;
    border: 1px solid #eeeeee;
    background-color: transparent;

    &:not(:disabled):hover {
      color: #1c1c1e;
      border-color: #bdbdbd;
      background-color: #fafafa;
    }
  }

  &.gold:not(:disabled) {
    padding: 12px 16px;

    color: #ffffff;
    border: none;
    background-color: #d9bc3e;

    &:not(:disabled):hover {
      background-color: #c7ac36;
      box-shadow: 0 6px 12px #00000020;
    }
  }

  &.action:not(:disabled) {
    color: #ffffff;
    border: ${({active}) => active ? 'none': '2px solid #80b520'};
    background-color: ${({active}) => active ? '#80b520': 'transparent'};

    &:not(:disabled):hover {
      background-color: #80b520;
      box-shadow: 0 6px 12px #00000020;
    }
  }

  &.invert:not(:disabled) {
    color: ${({active}) => active ? '#90ca28': '#FFF'};;
    border: none;
    background-color: transparent;

    &:not(:disabled):hover {
      color: #80b520;
    }
  }

  &.invert-border:not(:disabled) {
    color: #90ca28;
    border: 1px solid #90ca28;
    background-color: transparent;

    &:not(:disabled):hover {
      color: #ffffff;
      background-color: #90ca28;
    }
  }

  &.danger:not(:disabled) {
    color: #ffffff;
    border: none;
    background-color: #ff3b30;

    &:not(:disabled):hover {
      background-color: #ff3b30;
    }
  }
`;
