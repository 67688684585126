import React from 'react';

import { Container, Label, Value } from './styles';

const CardCountMatch = ({ value }) => {
  return (
    <Container>
      <Label>Rodadas em andamento</Label>
      <Value>{value}</Value>
    </Container>
  );
};

export default CardCountMatch;
