import styled from 'styled-components';


export const Controls = styled.div`
  display: flex;
  align-items: center;

  padding-bottom: 30px;

  > *:not(:last-child) {
    margin-right: 15px;
  }
`;