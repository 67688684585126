import styled, { css } from 'styled-components';

import fading from '~/styles/animations/fading';

export const Container = styled.div`
  display: flex;
  align-items: center;

  flex-direction: column;

  margin-bottom: 15px;
`;

export const Field = styled.input`
  display: none;
`;

export const Label = styled.label`
  cursor: pointer;
  user-select: none;

  position: relative;

  width: 100px;
  margin-top: 5px;

  font-size: 14px;
  font-weight: 500;

  color: #ffffff;
`;

export const Error = styled.span`
  color: #ff3b2f;
  font-size: 12px;
`;

export const Preview = styled.div`
  display: inline-block;
  width: 100px;
  height: 100px;
  border-radius: 16px;

  ${({ src }) => {
    if (src === '')
      return css`
        background-color: #d1d1d6;
        animation: ${fading} 1s infinite;
      `;

    if (src === 'no-image')
      return css`
        background-color: #ffffff;
      `;

    return css`
      background-position: center;
      background-size: cover;
      background-image: url('${src}');
      transition: background-image 200ms linear;
    `;
  }}
`;

export const BoxIconUpload = styled.span`
  position: absolute;
  right: 0;
  bottom: 0;

  transform: translate(30%, -50%);

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #90ca28;

  height: 28px;
  width: 28px;
  border-radius: 4px;

  svg {
    font-size: 20px;
  }
`;
