import styled from 'styled-components';

import { Container as Card } from '~/components/Card/styles';

export const Container = styled.div`
  padding: 30px;
`;

export const Content = styled(Card)`
  padding: 30px;

  .pagination {
    margin-top: 15px;
  }
`;

export const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-bottom: 30px;
`;

export const Title = styled.div`
  font-size: 24px;
  color: #ffffff;
`;
