import styled from 'styled-components';

import { Form } from '@unform/web';

import devices from '~/styles/configs/devices';

export const Container = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  top: 0;
  left: 0;
  background-color: #00000040;

  display: flex;
  align-items: center;
  justify-content: center;

  button {
    padding: 10px;

    &.invert {
      color: #e0e0e0;
      margin-right: 15px;

      &:not(:disabled):hover {
        color: #f5f5f5;
      }
    }
  }

  .container-input {
    padding-top: 5px;
    input {
      padding: 10px;
    }
  }

  @media ${devices.mobile} {
    padding: 15px;
  }
`;

export const Content = styled(Form)`
  width: 100%;
  max-width: 500px;
  background-color: #12154a;
  border-radius: 12px;
  padding: 60px;
  box-shadow: 1px 6px 12px rgba(0, 0, 0, 0.1);

  @media ${devices.mobile} {
    padding: 45px;
  }
`;

export const Title = styled.h1`
  margin-bottom: 30px;
  font-size: 24px;

  color: #ffffff;
`;

export const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 30px;
`;

export const Instruction = styled.div`
  font-size: 14px;
  color: #f5f5f5;

  margin-bottom: 2px;
`;

export const DeleteString = styled.span`
  color: #fafafa;
  font-weight: 700;
`;
