import styled from 'styled-components';

import { Container as Card } from '~/components/Card/styles';
import ripple from '~/styles/animations/ripple';

export const Container = styled(Card)`
	position: relative;
	overflow: hidden;

	padding: 15px 30px 45px 30px;

	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const TeamContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
`;

export const TeamName = styled.div`
	width: 100%;
	color: #ffffff;
	font-size: 16px;

	margin-top: 5px;
	text-align: center;
`;

export const Vs = styled.div`
	color: #90ca28;
	font-size: 16px;
	font-weight: 500;

	margin: 0 15px;
`;

export const Button = styled.button`
	${ripple}

	position: absolute;
	bottom: 0;
	left: 0;
	-webkit-appearance: none;

	text-align: center;
	font-weight: 500;
	font-size: 14px;
	padding: 8px 5px;

	width: 100%;

	transition: background-color 200ms linear;

	color: #ffffff;
	border: none;
	background-color: #90ca28;

	&:not(:disabled):hover {
		background-color: #80b520;
	}
`;

export const Empty = styled.span`
	color: #90ca28;
	font-size: 16px;
	align-text: center;
	font-weight: 500;
	margin: auto;
`;
