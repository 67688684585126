import React from 'react';

import PropTypes from 'prop-types';

import { Container } from './styles';

const Th = ({ width, children }) => {
  return <Container width={width}>{children}</Container>;
};

Th.defaultProps = {
  width: 'auto',
};

Th.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

export default Th;
