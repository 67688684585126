import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Table from '~/components/Table';
import api from '~/services/api';

import formatDateFns from 'date-fns/format';

import { Container, Title } from './styles';

const INITIAL_BETS = {
	data: [],
	page: 0,
	limit: 0,
	totalData: 0,
	totalPages: 0,
};

const TableLast = () => {
	const columns = [
		{
			key: 'name',
			label: 'Apostador',
		},
		{
			key: 'created_by.name',
			label: 'Vendedor',
		},
		{
			key: ['round_manager'],
			label: 'Rodada',
			format: ({ round_manager }) => {
				return round_manager.round.name;
			},
		},
		{
			key: ['created_at'],
			label: 'Data / Hora',
			format: ({ created_at }) => formatDateFns(new Date(created_at), 'dd-MM-yyyy HH:mm')
		}
	];

	const [bets, setBets] = useState(INITIAL_BETS);

	const [loading, setLoading] = useState(true);

	const getBets = useCallback(async (page = 1) => {
		try {
			const { data } = await api.get(`/bet?page=${page}&perPage=10&sort=-created_at`);

			setBets(data);
		} catch (error) {
			toast.error('Ocorreu um erro ao buscar as apostas...');
			setBets(INITIAL_BETS);
		} finally {
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		getBets();
	}, [getBets]);

	return (
		<Container>
			<Title>Apostas recentes</Title>

			<Table
				columns={columns}
				rows={bets.data}
				loading={loading}
				preLoadRows={10}
			/>
		</Container>
	);
};

export default TableLast;
