import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 65px;

  border-bottom: 1px solid #151a5d;
  background-color: #0f113e;

  z-index: 100;
`;

export const Navigation = styled.div`
  display: flex;
  height: 100%;

  > a:not(:last-of-type) {
    margin-right: 15px;
  }
`;
