import React, { useRef } from 'react';

import Button from '~/components/Button';

import ModalForm from './ModalForm';
import { Container, Head, Title } from './styles';
import Table from './Table';

const Team = () => {
  const modalFormRef = useRef(null);
  const tableRef = useRef(null);

  const handleClickOpenModal = (data) => modalFormRef.current.openModal(data);

  const handleOnSave = () => tableRef.current.getTeams();

  return (
    <Container>
      <Head>
        <Title>Times</Title>
        <Button label="Novo time" onClick={handleClickOpenModal} />
        <ModalForm ref={modalFormRef} onSave={handleOnSave} />
      </Head>
      <Table ref={tableRef} />
    </Container>
  );
};

export default Team;
