import styled, { css, keyframes } from 'styled-components';

import fadeIn from 'react-animations/lib/fade-in';
import zoomIn from 'react-animations/lib/zoom-in';

import devices from '~/styles/configs/devices';

const fadeInAnimation = keyframes`${fadeIn}`;

const zoomInAnimation = keyframes`${zoomIn}`;

export const Container = styled.div`
  position: fixed;

  overflow-y: scroll;

  animation: 200ms ${fadeInAnimation};

  width: 100vw;
  height: 100vh;
  z-index: 99999;
  top: 0;
  left: 0;
  background-color: #00000040;

  display: flex;
  /* align-items: center; */
  justify-content: center;

  @media ${devices.mobile} {
    padding: 15px;
  }
`;

export const Content = styled.div`
  form {
    position: relative;

    animation: 300ms ${zoomInAnimation};

    width: 100%;
    background-color: #12154a;
    border-radius: 12px;
    padding: 60px;
    box-shadow: 1px 6px 12px rgba(0, 0, 0, 0.1);

    ${({ maxWidth }) =>
      css`
        max-width: ${maxWidth}px;
      `};

    @media ${devices.mobile} {
      padding: 45px;
    }

    .root-input,
    .root-select,
    .root-color-picker {
      margin-bottom: 15px;
    }
  }
`;

export const Title = styled.h1`
  margin-bottom: 30px;
  font-size: 24px;

  color: #ffffff;
`;

export const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 15px;
`;

export const ButtonClose = styled.button`
  position: absolute;

  top: 30px;
  right: 30px;

  border: none;
  background: none;

  svg {
    pointer-events: none;
    font-size: 32px;
    color: #e0e0e0;
    transition: color 200ms linear;
  }

  &:hover svg {
    color: #ff3b30;
  }
`;
