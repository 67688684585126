import React from 'react';

import { useSelector } from 'react-redux';

import api from '~/services/api';

import Actions from '../Actions';
import { Container, Avatar, Initials, Info, Name, Role } from './styles';

const Profile = () => {
  const { user, token } = useSelector((state) => state.auth);

  api.defaults.headers.Authorization = `Bearer ${token}`;

  const roles = {
    admin: 'Administrador',
    manager: 'Gerente',
    sub_manager: 'Subgerente',
    seller: 'Vendedor',
  };

  const getInitials = () => {
    const names = user.name.split(' ');

    if (Array.isArray(names)) {
      return names.map((name) => name.charAt(0));
    }

    return user.name.charAt(0);
  };

  return (
    <Container>
      {user.photo_profile ? (
        <Avatar src={user.photo_profile} />
      ) : (
        <Initials>{getInitials()}</Initials>
      )}
      <Info>
        <Name>{user.name}</Name>
        <Role>{roles[user.role]}</Role>
      </Info>
      <Actions />
    </Container>
  );
};

export default Profile;
