import React from 'react';

import PropTypes from 'prop-types';

import { Container } from './styles';

const Button = ({ disabled, type, label, fullWidth, color, className, active, ...rest }) => {
  return (
    <Container
			active={active}
			disabled={disabled}
      className={`root-button ${className} ${color}`}
      fullWidth={fullWidth}
      type={type}
      {...rest}
    >
      {label}
    </Container>
  );
};

Button.defaultProps = {
  type: 'button',
  className: '',
  color: 'action',
  active: true,
  fullWidth: false,
};

Button.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  label: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
};

export default Button;
