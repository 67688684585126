import styled, { css } from 'styled-components';

import fading from '~/styles/animations/fading';

export const Container = styled.div`
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;

  border-radius: 4px;

  ${({ src }) => {
    if (src === '')
      return css`
        background-color: #d1d1d6;
        animation: ${fading} 1s infinite;
      `;

    if (src === 'no-image')
      return css`
        background-color: #ffffff;
      `;

    return css`
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url('${src}');
      transition: background-image 200ms linear;
    `;
  }}
`;
