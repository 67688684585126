import React from 'react';

import { IoIosLogOut } from 'react-icons/io';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Creators as AuthActions } from '~/store/ducks/auth';

import { Container } from './styles';

const Logout = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const handleClickLogout = () => {
    dispatch(AuthActions.logout());
    history.push('/admin/login');
  };

  return (
    <Container type="button" onClick={handleClickLogout}>
      <IoIosLogOut />
    </Container>
  );
};

export default Logout;
