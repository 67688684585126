import React from 'react';

import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import ModalDelete from '~/components/ModalDelete';

import Routes from './routes/index';
import GlobalStyle from './styles/global';

const history = createBrowserHistory();

const App = () => {
  return (
    <Router history={history}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <ToastContainer />
      <ModalDelete>
        <GlobalStyle />
        <Routes />
      </ModalDelete>
    </Router>
  );
};

export default App;
