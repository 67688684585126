import yup from '~/libs/yup';

export const createSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  email: yup.string().email().required().label('E-mail'),
  phone: yup.string().length(15).required().label('Celular'),
});

export const updateSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  email: yup.string().email().required().label('E-mail'),
  phone: yup.string().length(15).required().label('Celular'),
});
