import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useRef,
} from 'react';

import formatDateFns from 'date-fns/format';
import ptLocaleDateFns from 'date-fns/locale/pt';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import GroupInput from '~/components/GroupInput';
import Input from '~/components/Input';
import Modal from '~/components/Modal';
import useModal from '~/hooks/useModal';
import api from '~/services/api';
import yupValidate from '~/utils/yupValidate';
import { createSchema, updateSchema } from '~/validators/game.schema';

import SelectTeams from './SelectTeams';

const ModalForm = ({ ...rest }, ref) => {
  const { id } = useParams();

  const formRef = useRef(null);

  const { isOpen, closeModal, openModal } = useModal();

  const [initialData, setInitialData] = useState({});

  useImperativeHandle(ref, () => ({
    openModal: (data = {}) => {
      setInitialData(data);
      openModal();
    },
  }));

  const handleSubmit = async (formData) => {
    try {
      formRef.current.setErrors({});

      const validateSchema = initialData._id ? updateSchema : createSchema;

      const [year, month, day] = formData.date.split('-');
      const [hours, minutes] = formData.time.split(':');

      const date = new Date();
      date.setFullYear(Number(year), Number(month -1), Number(day));
      date.setHours(Number(hours), Number(minutes));

      const { success, data, errors } = await yupValidate(validateSchema, {
        ...formData,
        date,
      });

      if (!success) {
        return formRef.current.setErrors(errors);
      }

      // Dados básicos
      if (initialData._id) {
        await api.put('/game', { id: initialData._id, ...data, round_id: id });
      } else {
        await api.post('/game', { ...data, round_id: id });
      }

      closeModal();

      toast.success(
        `Jogo ${initialData._id ? 'cadastrado' : 'editado'}  com sucesso!`
      );

      if (rest.onSave) rest.onSave();

      return {};
    } catch (error) {
      if (error.response) {
        const { status, data } = error.response;

        if (status === 400) {
          return toast.error(`${data.error}`);
        }
      }

      return toast.error(
        `Ocorreu um erro ao tentar ${
          initialData._id ? 'cadastrar' : 'editar'
        } o jogo...`
      );
    }
  };

  return (
    <Modal
      formRef={formRef}
      title={!initialData._id ? 'Novo Jogo':'Editar Jogo'}
      isOpen={isOpen}
      closeModal={closeModal}
      onSubmit={handleSubmit}
      initialData={initialData}
      maxWidth={600}
    >
      <Input label="Campeonato" name="championship" variant="border" />
      <SelectTeams />
      <GroupInput gap={15} columns={['3fr', '1fr']}>
        <Input
          type="date"
          label="Data"
          name="date"
          variant="border"
          defaultValue={formatDateFns(new Date(initialData.date || new Date()), 'yyyy-MM-dd', {
            locale: ptLocaleDateFns,
          })}
        />
        <Input
          type="time"
          label="Hora"
          name="time"
          variant="border"
          min="00:00"
          max="23:59"
          defaultValue={formatDateFns(new Date(initialData.date || new Date()), 'HH:mm', {
            locale: ptLocaleDateFns,
          })}
        />
      </GroupInput>
    </Modal>
  );
};

export default forwardRef(ModalForm);
