import React, { useRef } from 'react';

import FadeIn from 'react-fade-in';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ReactComponent as Logo } from '~/assets/svg/logo.svg';
import Button from '~/components/Button';
import Form from '~/components/Form';
import api from '~/services/api';
import yupValidate from '~/utils/yupValidate';
import { consultSchema } from '~/validators/bet.schema';

import InputCode from './InputCode';
import { Container, Content } from './styles';

const Consult = () => {
  const formRef = useRef();

  const history = useHistory();

  const handleSubmit = async (formData) => {
    try {
      formRef.current.setErrors({});

      const { success, data, errors } = await yupValidate(consultSchema, {
        ...formData,
      });

      if (!success) {
        return formRef.current.setErrors(errors);
      }

      const response = await api.get(`/bet/code/${data.code}`);

      return history.push(`/${data.code}`, {
        data: response.data,
      });
    } catch (error) {
      const { status } = error.response;

      if (status) {
        return formRef.current.setErrors({
          code: 'O código da aposta é invalido',
        });
      }

      toast.error(
        'Ocorreu um erro ao entrar ao buscar a aposta, verifique o código e tente novamente...'
      );

      return false;
    }
  };

  return (
    <Container>
      <Content>
        <FadeIn>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Logo />
            <InputCode name="code" fields={7} />
            <Button
              type="submit"
              label="Acompanhar aposta"
              color="gold"
              fullWidth
            />
          </Form>
        </FadeIn>
      </Content>
    </Container>
  );
};

export default Consult;
