import React from 'react';

import differenceInHours from 'date-fns/differenceInHours';
import format from 'date-fns/format';
import parseJSON from 'date-fns/parseJSON';
import PropTypes from 'prop-types';
import FadeIn from 'react-fade-in';

import TeamAvatar from '~/components/TeamAvatar';

import {
  Container,
  Header,
  Hit,
  Result,
  Championship,
  TeamsContainer,
  TeamHostProfile,
  TeamGuestProfile,
  TeamName,
  VsContainer,
  VsText,
  VsResult,
  GameDate,
} from './styles';

const CardGame = ({ data }) => {
  const { hit, bet, date, result, championship, team_host, team_guest } = data;

  const dateFormatted = parseJSON(date);
  const currentDate = new Date();

  const availableToSetResult =
    differenceInHours(currentDate, dateFormatted) > 2;

  return (
    <FadeIn>
      <Container availableToSetResult={availableToSetResult && !result}>
        <Header>
          <Championship>{championship}</Championship>
          <div style={{ display: 'flex' }}>
            <Result>
              {bet === 'host' && team_host.name}
              {bet === 'tie' && 'Empate'}
              {bet === 'guest' && team_guest.name}
            </Result>
            {result && <Hit hit={hit}>{hit}</Hit>}
          </div>
        </Header>
        <TeamsContainer>
          <TeamHostProfile result={result}>
            <TeamAvatar src={team_host.shield} size={80} />
            <TeamName>{team_host.name}</TeamName>
          </TeamHostProfile>
          <VsContainer>
            <VsText>VS</VsText>
            {result === 'tie' && <VsResult>Empate</VsResult>}
          </VsContainer>
          <TeamGuestProfile result={result}>
            <TeamAvatar src={team_guest.shield} size={80} />
            <TeamName>{team_guest.name}</TeamName>
          </TeamGuestProfile>
        </TeamsContainer>
        <GameDate>
          {format(dateFormatted, "dd/MM/yyyy 'às' H:mm 'hrs'")}
        </GameDate>
      </Container>
    </FadeIn>
  );
};

CardGame.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string,
    hit: PropTypes.bool,
    result: PropTypes.string,
    championship: PropTypes.string,
    date: PropTypes.string,
    team_host: PropTypes.shape({
      _id: PropTypes.string,
      shield: PropTypes.string,
      name: PropTypes.string,
    }),
    team_guest: PropTypes.shape({
      _id: PropTypes.string,
      shield: PropTypes.string,
      name: PropTypes.string,
    }),
  }).isRequired,
};

export default CardGame;
