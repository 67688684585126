import styled, { css } from 'styled-components';

import { Container as Card } from '~/components/Card/styles';
import fading from '~/styles/animations/fading';

export const Container = styled(Card)`
  padding: 30px;
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;
`;

export const Image = styled.div`
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 4px;

  margin-right: 10px;

  ${({ src }) => {
    if (src === '')
      return css`
        background-color: #d1d1d6;
        animation: ${fading} 1s infinite;
      `;

    if (src === 'no-image')
      return css`
        background-color: #ffffff;
      `;

    return css`
      background-position: center;
      background-size: cover;
      background-image: url('${src}');
      transition: background-image 200ms linear;
    `;
  }}
`;
