import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useEffect,
  useState,
  useRef,
} from 'react';

import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import formatDateFns from 'date-fns/format';

import Pagination from '~/components/Pagination';
import RoundTable from '~/components/Table';
import TableActions from '~/components/TableActions';
import useIsMountedRef from '~/hooks/useIsMountedRef';
import useModalDelete from '~/hooks/useModalDelete';
import api from '~/services/api';
import masks from '~/utils/masks';

import ModalForm from '../ModalForm';
import { Container } from './styles';

const INITIAL_ROUNDS = {
  data: [],
  page: 0,
  limit: 0,
  totalData: 0,
  totalPages: 0,
};

const Table = (props, ref) => {
  const history = useHistory();

  const modalFormRef = useRef(null);

  const { openModalDelete } = useModalDelete();

  const isMountedRef = useIsMountedRef();

  const [rounds, setRounds] = useState(INITIAL_ROUNDS);
  const [loading, setLoading] = useState(true);

  const getRounds = useCallback(async (page = 1) => {
    try {
      const { data } = await api.get(`/round?page=${page}&perPage=10`);

      setRounds(data);
    } catch (error) {
      toast.error('Ocorreu um erro ao buscar as rodadas...');
      setRounds(INITIAL_ROUNDS);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (isMountedRef) getRounds();
  }, [isMountedRef, getRounds]);

  useImperativeHandle(ref, () => ({
    getRounds,
  }));

  const handleClickDelete = async (event) => {
    try {
      event.persist();

      const { value } = event.target;

      openModalDelete({
        url: `/round/${value}`,
        title: 'Deseja deletar a rodada?',
        onDelete: () => getRounds(1),
        autoClose: true,
      });
    } catch (error) {
      console.log('>>> error', error);
    }
  };

  const handleClickView = async (event) => {
    event.persist();

    const { value } = event.target;

    history.push(`/admin/rodadas/${value}`);
  };

  const columns = [
    {
      key: ['name', 'region'],
      label: 'Nome',
      format: ({ name, region }) => `${name} - ${region}`,
    },
    {
      key: 'start',
      label: 'Inícia Em',
      format: (start) => formatDateFns(new Date(start), 'dd-MM-yyyy')
    },
    {
      key: 'firstGame',
      label: 'Fechamento',
      format: (start) => formatDateFns(new Date(start), 'dd-MM-yyyy HH:mm')
    },
    {
      key: 'finish',
      label: 'Encerramento',
      format: (start) => formatDateFns(new Date(start), 'dd-MM-yyyy HH:mm')
    },
    {
      key: 'max_bets',
      label: 'Máximo de apostas',
    },
    {
      key: 'value_bet',
      label: 'Aposta',
      format: (value) => masks.currency(value),
    },
    {
      key: 'award',
      label: 'Prêmio (%)',
    },
    {
      key: '_id',
      label: 'Ações',
      width: 100,
      format: (_id) => (
        <TableActions
          value={_id}
          onView={handleClickView}
          onDelete={handleClickDelete}
        />
      ),
    },
  ];

  return (
    <Container>
      <ModalForm ref={modalFormRef} onSave={getRounds} />
      <RoundTable
        columns={columns}
        rows={rounds.data}
        loading={loading}
        preLoadRows={10}
      />
      <Pagination
        onChange={getRounds}
        page={rounds.page}
        pageSize={rounds.limit}
        total={rounds.totalData}
        totalPages={rounds.totalPages}
        position="flex-end"
      />
    </Container>
  );
};

export default forwardRef(Table);
