import styled from 'styled-components';

// import devices from '~/styles/configs/devices';
import { Container as Card } from '~/components/Card/styles';

export const Container = styled(Card)`
  display: grid;
  grid-template-rows: 1fr 1fr;

  padding: 30px;
`;

export const Label = styled.div`
  color: #ffffff;
  font-size: 16px;
`;

export const Value = styled.div`
  color: #90ca28;
  font-size: 42px;
  font-weight: 900;
`;
