import React from 'react';

import PropTypes from 'prop-types';

import { Container } from './styles';

const TeamAvatar = ({ src, size }) => {
  return <Container className="root-team-avatar" src={src} size={size} />;
};

TeamAvatar.defaultProps = {
  size: 24,
};

TeamAvatar.propTypes = {
  size: PropTypes.number,
  src: PropTypes.string.isRequired,
};

export default TeamAvatar;
