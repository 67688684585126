import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  login: ['token', 'expiresIn', 'user'],
  logout: [''],
  getToken: [''],
  getUserData: [''],
});

const INITIAL_STATE = {
  token: null,
  expiresIn: null,
  user: null,
};

const getToken = (state) => {
  const { token } = state;

  return token;
};

const getUserData = (state) => {
  const { user } = state;

  return user;
};

const login = (state = INITIAL_STATE, { token, expiresIn, user }) => {
  return { ...state, token, expiresIn, user };
};

const logout = () => {
  return INITIAL_STATE;
};

export default createReducer(INITIAL_STATE, {
  [Types.GET_TOKEN]: getToken,
  [Types.GET_USER_DATA]: getUserData,
  [Types.LOGIN]: login,
  [Types.LOGOUT]: logout,
});
