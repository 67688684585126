import React, { useCallback, useEffect, useRef, useState } from 'react';

import TeamAvatar from '~/components/TeamAvatar';
import api from '~/services/api';

import ModalGame from './ModalGame';

import {
	Container,
	TeamContainer,
	TeamName,
	Vs,
	Button,
	Empty,
} from './styles';

const CardLastGame = () => {
	const INITIAL_USERS = {
		data: [],
	};

	const [lastGame, setLastGame] = useState(INITIAL_USERS);

	const [loading, setLoading] = useState(true);
	const modalRef = useRef(null);

	const getLastGame = useCallback(async () => {
		setLoading(true);
		try {
			const { data } = await api.get(`/game?page=1&perPage=1&lastgame=true`);

			setLastGame(data);
		} catch (error) {
			setLastGame(INITIAL_USERS);

			//toast.error(`Ocorreu um erro ao buscar os usuários...`);
		} finally {
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		getLastGame();
	}, []);

	const handleOpenModal = (data = {}) => {
		modalRef.current.openModal(data);
	};

	return (
		<Container>
			{!loading && lastGame.data.length > 0 ? (
				<>
					<TeamContainer>
						<TeamAvatar src={lastGame.data[0].team_host.shield} size={45} />
						<TeamName>{lastGame.data[0].team_host.name}</TeamName>
					</TeamContainer>
					<Vs>VS</Vs>
					<TeamContainer>
						<TeamAvatar src={lastGame.data[0].team_guest.shield} size={45} />
						<TeamName>{lastGame.data[0].team_guest.name}</TeamName>
					</TeamContainer>
					<Button
						onClick={() => handleOpenModal(lastGame.data[0])}
						type="button"
					>
						Informar resultado
					</Button>
				</>
			) : (
				<Empty>Nenhum resultado pendente</Empty>
			)}

			{loading && <></>}
			<ModalGame ref={modalRef} onSave={getLastGame} />
		</Container>
	);
};

export default CardLastGame;
