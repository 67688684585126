import styled from 'styled-components';

export const Container = styled.div`
  .react-code-input {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
`;

export const inputStyle = {
  inputStyle: {
    fontFamily: 'Roboto',
    margin: '4px',
    MozAppearance: 'textfield',
    width: '40px',
    height: '40px',
    paddingLeft: '12px',
    border: '1px solid #e0e0e0',
    borderRadius: '3px',
    fontSize: '20px',
    color: '#c7c7c7',
  },
  inputStyleInvalid: {
    color: 'red',
    border: '1px solid red',
  },
};

export const Error = styled.span`
  color: #ff3b2f;
  font-size: 12px;
`;
