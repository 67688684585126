import yup from '~/libs/yup';

export const createSchema = yup.object().shape({
  championship: yup.string().label('Campeonato'),
  date: yup.date().required().label('Data'),
  time: yup
    .string()
    .matches(
      new RegExp('^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$'),
      'A hora deve ser no formato 00:00'
    )
    .required()
    .label('Hora'),
  team_host_id: yup.string().length(24).required().label('Time mandante'),
  team_guest_id: yup
    .string()
    .length(24)
    .diffTo(yup.ref('team_host_id'))
    .required()
    .label('Time visitante'),
});

export const updateSchema = yup.object().shape({
  championship: yup.string().label('Campeonato'),
  date: yup.date().label('Data'),
  time: yup
    .string()
    .matches(
      new RegExp('^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$'),
      'A hora deve ser no formato 00:00'
    )
    .label('Hora'),
  team_host_id: yup.string().length(24).label('Time mandante'),
  team_guest_id: yup
    .string()
    .length(24)
    .diffTo(yup.ref('team_host_id'))
    .label('Time visitante'),
});
