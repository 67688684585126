import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useRef,
} from 'react';

import { toast } from 'react-toastify';

import GroupInput from '~/components/GroupInput';
import Input from '~/components/Input';
import Modal from '~/components/Modal';
import useModal from '~/hooks/useModal';
import api from '~/services/api';
import yupValidate from '~/utils/yupValidate';
import { createSchema, updateSchema } from '~/validators/team.schema';

import Address from './Address';
import Image from './Image';

const ModalForm = ({ ...rest }, ref) => {
  const formRef = useRef(null);

  const { isOpen, closeModal, openModal } = useModal();

  const [initialData, setInitialData] = useState({});

  useImperativeHandle(ref, () => ({
    openModal: (data = {}) => {
      setInitialData(data);
      openModal();
    },
  }));

  const saveImage = async ({ _id, file }) => {
    try {
      // Upload da Logo
      const formDataImage = new FormData();
      formDataImage.append('id', _id);
      formDataImage.append('shield', file);

      const response = await api.put('/team/shield', formDataImage);

      toast.success('Imagem cadastrada com sucesso!');

      return response;
    } catch (error) {
      toast.error(`Ocorrereu um erro ao salvar a imagem...`);
      return error;
    }
  };

  const createTeam = async (data) => {
    const response = await api.post('/team', data);

    return response;
  };

  const updateTeam = async (data) => {
    console.log(data);
    const response = await api.put('/team', data);

    return response;
  };

  const handleSubmit = async (formData) => {
    try {
      formRef.current.setErrors({});

      const validationSchema = initialData._id ? updateSchema : createSchema;

      if (initialData._id && formData.shield.length === 0)
        delete formData.shield;

      const { success, data, errors } = await yupValidate(
        validationSchema,
        formData
      );

      if (!success) {
        return formRef.current.setErrors(errors);
      }

      // Dados básicos
      const {
        data: { _id },
      } = initialData._id
        ? await updateTeam({ id: initialData._id, ...data })
        : await createTeam(data);

      if (!initialData._id || data.shield)
        await saveImage({ _id, file: data.shield[0] });

      closeModal();

      toast.success('Time cadastrado com sucesso!');

      if (rest.onSave) rest.onSave();

      return _id;
    } catch (error) {
      if (error.response) {
        const { status, data } = error.response;

        if (status === 400) {
          return toast.error(
            `Ocorreram erros na validação... Erros: ${data.error.join('')}`
          );
        }
      }

      return toast.error(
        `Ocorreu um erro ao tentar ${
          initialData._id ? 'cadastrar' : 'editar'
        } o jogo...`
      );
    }
  };

  const getCountry = () => {
    if (initialData.address) return initialData.address.country;

    return '';
  };

  return (
    <Modal
      formRef={formRef}
      title="Novo time"
      isOpen={isOpen}
      closeModal={closeModal}
      onSubmit={handleSubmit}
      initialData={initialData}
    >
      <Image />
      <GroupInput gap={15} columns={['3fr', '1fr']}>
        <Input label="Nome" name="name" variant="border" />
        <Input label="Sigla" name="initials" variant="border" />
      </GroupInput>
      <Address defaultCountry={getCountry()} />
    </Modal>
  );
};

export default forwardRef(ModalForm);
