import React, { useCallback, useEffect, useState, useRef } from 'react';

import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import differenceInHours from 'date-fns/differenceInHours';

import Button from '~/components/Button';
import useIsMountedRef from '~/hooks/useIsMountedRef';
import api from '~/services/api';

import CardGame from './CardGame';
import {
	Container,
	Head,
	Title,
	ListGames,
	Message,
	MessageHighlight,
} from './styles';
import ControlsTabs from './ControlsTabs';
import ModalForm from './ModalForm';

const View = () => {
	const { id } = useParams();

	const history = useHistory();

	const modalRef = useRef(null);

	const isMountedRef = useIsMountedRef();

	const [loading, setLoading] = useState(true);
	const [canEdit, setCanEdit] = useState({});
	const [round, setRound] = useState(false);

	const getRound = useCallback(async () => {
		try {
			const { data } = await api.get(`/round/${id}`);

			let canEditResult = 
				data.games[9] &&
				differenceInHours(new Date(), new Date(data.games[9].updated_at)) < 1;

			setCanEdit({
				result: true,
				game: !data.hasBets,
			});

			setRound(data);
		} catch (error) {
			toast.error('Ocorreu um erro ao buscar a rodada...');
			setRound([]);
		} finally {
			setLoading(false);
		}
	}, [id]);

	const handleSelectResult = async (id, result) => {
		try {
			const response = await api.put(`/game`, { id, result });
		} catch (error) {
			console.log('>>> error', error);
		}
	};

	useEffect(() => {
		if (isMountedRef) getRound();
	}, [isMountedRef, getRound]);

	const handleOpenModal = (data = {}) => {
		modalRef.current.openModal(data);
	};

	return (
		<Container>
			<Head>
				<Title>Rodada - {round.name}</Title>
			</Head>
			<ControlsTabs
				availableForBet={round.availableForBet}
				canCreateGame={(round.max_games !== round?.games?.length)}
				getRound={getRound}
				round={round}
				active="Jogos"
				roundId={id}
			/>
			<ListGames>
				{round?.games?.map((game) => (
					<CardGame
						key={game._id}
						data={game}
						hasTie={round.has_tie}
						canEdit={canEdit}
						onSetResult={handleSelectResult}
						onEdit={handleOpenModal}
						onDelete={getRound}
						onSave={getRound}
					/>
				))}
			</ListGames>
			{round?.games?.length === 0 && !loading && (
				<Message>
					Rodada criada com sucesso! Agora você precisa cadastrar {round.max_games} jogos para
					esta rodada clicando no botão
					<MessageHighlight> ‘Novo Jogo’</MessageHighlight>
				</Message>
			)}
			<ModalForm ref={modalRef} onSave={getRound} />
		</Container>
	);
};

export default View;
