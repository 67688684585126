import React from 'react';

import { Container, Label, Value } from './styles';

const CardCountBet = ({ value }) => {
	return (
		<Container>
			<Label>Apostas realizadas</Label>
			<Value>{value}</Value>
		</Container>
	);
};

export default CardCountBet;
