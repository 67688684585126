import React, {
	forwardRef,
	useImperativeHandle,
	useState,
	useRef,
	useEffect,
} from 'react';

import { toast } from 'react-toastify';

import Modal from '~/components/Modal';
import useModal from '~/hooks/useModal';
import CardGame from '~/pages/Round/View/CardGame';
import api from '~/services/api';

const ModalGame = ({ onSave, ...rest }, ref) => {
	const formRef = useRef(null);
	const [initialData, setInitialData] = useState({});

	const { isOpen, closeModal, openModal } = useModal();

	useImperativeHandle(ref, () => ({
		openModal: (data = {}) => {
			setInitialData(data);
			openModal();
		},
	}));

	const handleModalSave = () => {
		onSave();

		closeModal();
	};

	return (
		<Modal
			formRef={formRef}
			isOpen={isOpen}
			closeModal={closeModal}
			initialData={initialData}
			hasBackground={false}
		>
			<></>
			<CardGame data={initialData} onSave={handleModalSave} />
		</Modal>
	);
};

export default forwardRef(ModalGame);
