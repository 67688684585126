import React, { useRef, useState } from 'react';

import { useHistory } from 'react-router-dom';

import Button from '~/components/Button';
import masks from '~/utils/masks';
import api from '~/services/api';
import jsPDF from 'jspdf';

import ModalForm from '../ModalForm';
import { Controls } from './styles';

const ControlsTabs = ({
	round,
	canCreateGame,
	active,
	getRound,
	roundId,
}) => {
	const modalRef = useRef(null);

	const history = useHistory();
	const handleOpenModal = (data = {}) => {
		modalRef.current.openModal(data);
	};

	const [loading, setLoading] = useState(false);

	const getFinancial = async () => {
		setLoading(true);
		try {
			const { data } = await api.get('/statistics/financial', {
				params: {
					round_id: roundId,
				},
			});

			var doc = new jsPDF();
			
			doc.setFontSize(30);
			doc.text(round.name, 100, 25, 'center');

			doc.setFontSize(20);
			doc.text(`Quantidade de apostas - ${data.bets}`, 15, 35);
			doc.text(`Prêmio - ${masks.currency(data.award)}`, 15, 45);
			doc.text(`Comissão - ${masks.currency(data.commission)}`, 15, 55);
			
			doc.text(`Gerentes:`, 15, 70);

			let y = 80
			data.users.forEach(user => {
				doc.setFontSize(17)
				doc.text(user.name, 15, y);

				doc.setFontSize(14)
				doc.text(`Apostas: ${user.bets} - Repasse ${masks.currency(user.transfer)}`, 15, y+8);

				y+= 20
			});

			doc.save(`Financeiro ${round.name}.pdf`);
		} catch (err) {
			console.log(err);
			setLoading(false);
		}
	};

	const handleGenerate = () => {
		getFinancial();
	};

	return (
		<>
			<Controls>
				<Button
					label="Novo jogo"
					disabled={!canCreateGame}
					onClick={() => handleOpenModal()}
				/>
				<Button
					onClick={() => handleGenerate()}
					label="Relatório Financeiro"
					active={active === 'Relatorios'}
				/>
			</Controls>
			<ModalForm ref={modalRef} onSave={getRound} />
			<Controls>
				<Button
					label="Jogos"
					className="invert"
					active={active === 'Jogos'}
					onClick={() => history.push(`/admin/rodadas/${roundId}`)}
				/>
				<Button
					active={active === 'Apostas'}
					className="invert"
					onClick={() => history.push(`/admin/rodadas/${roundId}/apostas`)}
					label="Apostas"
				/>
				<Button
					label="Grupos"
					className="invert"
					active={active === 'Grupos'}
					onClick={() => history.push(`/admin/rodadas/${roundId}/grupos`)}
				/>
			</Controls>
		</>
	);
};

export default ControlsTabs;
